import {RemainingTime} from './types'

export const epochSecondsToEpoch = (s : number) : number => {
    return s * 1000
}

export const determineEndEpochSeconds = (intake_time: number, time_owed: number) : number => {
    let timeOwedInSeconds = time_owed * 60
    return intake_time + timeOwedInSeconds
}   

export const dateFromEpochSeconds = (epochSeconds : number) : Date => {
    return new Date(epochSeconds * 1000)
}

export const calculateTimeRemaining = (endEpochSeconds: number) : RemainingTime => {
    let nowEpochSeconds = Math.floor(Date.now() /1000)
    
    let diff = endEpochSeconds - nowEpochSeconds

    if ( diff < 0 ) return { minutes: 0, seconds: 0 }


    return { 
        minutes: Math.floor((diff / 60) % 60),
        seconds: Math.floor(diff % 60)
    }   
}

export const secondsToRemainingTime = (s : number) : RemainingTime => {
    if ( s === 0) return { minutes: 0, seconds: 0 }
    
    return {
        minutes: Math.floor((s / 60) % 60),
        seconds: Math.floor(s % 60)
    }
}

export const padTime = (time: number) : string => {
    let str = time.toString()

    return str.length < 2 ? `0${str}` : str
}