import React, { useEffect, useState } from 'react'
import { Container, DialogTitle, DialogContent, TextField, Select, MenuItem, FormControl, InputLabel, Stack, FormLabel } from '@mui/material';
import { TimerType } from 'components/timers/types';
import { useAppDispatch } from 'app/hooks';
import { getTimerDisplayText } from 'functions/timers';
import { DialogActions } from 'components/buttons/DialogActions';
import { BehaviorCodeTimer } from 'dtos/timers';
import { putTimer } from 'features/timers/timersSlice';
import { getCurrentEpochSeconds } from 'functions/time';
import { TimeOwedInput } from 'components/time_owed/TimeOwedInput';

interface AddBasicBehaviorCodeDialogProps {
    setOpen: Function
}

export const AddBasicBehaviorCodeDialog= ({ setOpen } : AddBasicBehaviorCodeDialogProps) => {
    const dispatch = useAppDispatch()
    const [label, setLabel] = useState<string>("")
    const [hoursOwed, setHoursOwed] = useState<number>(0)
    const [minutesOwed, setMinutesOwed] = useState<number>(0)
    const [secondsOwed, setSecondsOwed] = useState<number>(0)
    const [timerType, setTimerType] = useState<TimerType>(TimerType.NumericCountdown)
    const [ ready, setReady ] = useState<boolean>(false)

    useEffect(() => {
        if (ready) {
            let currentEpochSeconds = getCurrentEpochSeconds()
            // let seconds_owed = getSecondsOwed(timeOwed, timeUnits)
            let seconds_owed = (hoursOwed * 60 * 60) + (minutesOwed * 60) + secondsOwed

            let behaviorCodeTimer : BehaviorCodeTimer = {
                behavior_code_id: Date.now().toString(),
                color: "#2492D9",
                timer_label: label,
                timer_type: timerType,
                seconds_owed,
                seconds_completed: 0,
                timer_start_epoch_seconds: currentEpochSeconds,
                timer_end_epoch_seconds: currentEpochSeconds + seconds_owed,
                timer_paused_epoch_seconds: 0,
                paused: false,
                completed: false,
            }

            dispatch(putTimer(behaviorCodeTimer))
            setOpen(false)
        }
    }, [ready])


    return (
        <Container sx={{width: '25rem'}}> 
            <DialogTitle sx={{ margin: "auto", textAlign: 'center' }}>Add To Quick Room</DialogTitle>

            <DialogContent>
                <Stack direction="column" spacing={5}>
                    <TextField 
                        id="student"
                        label="Student Name"
                        value={label}
                        variant="standard"
                        onChange={(e) => { setLabel(e.target.value) }}
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                        }}
                    />

                    <FormLabel>Time Owed</FormLabel>
                    <TimeOwedInput 
                        onHoursChange={setHoursOwed}
                        onMinutesChange={setMinutesOwed}
                        onSecondsChange={setSecondsOwed}
                    />
                    
                    <FormControl>
                        <InputLabel id="timerType">Timer Type</InputLabel>
                        <Select
                            labelId='timerType'
                            id='timerType'
                            value={timerType}
                            label="Timer Type"
                            onChange={
                                // @ts-ignore
                                (e) => { 
                                // e.target.value is an int
                                //@ts-ignore
                                setTimerType(e.target.value)
                            }}
                        >
                            <MenuItem value={TimerType.NumericCountdown}>{getTimerDisplayText(TimerType.NumericCountdown)}</MenuItem>
                            <MenuItem value={TimerType.DonutFill}>{getTimerDisplayText(TimerType.DonutFill)}</MenuItem>
                            <MenuItem value={TimerType.LinearFill}>{getTimerDisplayText(TimerType.LinearFill)}</MenuItem>
                            <MenuItem value={TimerType.DonutDrain}>Donut Drain</MenuItem>
                            <MenuItem value={TimerType.LinearDrain}>Linear Drain</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>

            <DialogActions
                onCancel={() => { setOpen(false) }}
                onSubmit={() => { setReady(true) }}
                submitText="Add Timer"
            />
        </Container>
    )
}