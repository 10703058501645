import React, { useState } from 'react';
import { Container, Avatar, Stack, Grid, Box, Divider, Typography } from '@mui/material';
import { Referral } from '../../../types/dtos';

export interface DischargedReferralGridItemProps {
    referral: Referral
}

const getDate = (time : number | undefined) : Date => {
    if (!time) {
        return new Date()
    } 
    
    return new Date(time * 1000)
}
export const DischargedReferralGridItem = ({ referral } : DischargedReferralGridItemProps) => {
    return (
        <>
            <Box width='75%' key={referral.id} border={1} borderColor="#2492D9">
                <Stack 
                    sx={{ margin: 1, alignItems: 'center' }}
                    spacing={1}
                    direction="row"
                >
                    {/* <Container>  */}
                        <Stack 
                            alignItems='center'
                            sx={{ width: '50%' }} 
                            spacing={1}
                        >
                            <Avatar src={referral?.student?.image} sx={{ width: 72, height: 72}}></Avatar>
                            
                            <Typography>Student: {referral?.student?.full_name}</Typography>
                            
                            <Typography>Teacher: {referral?.teacher?.full_name}</Typography>
                            <Typography>Referrer: {referral?.referrer?.full_name}</Typography>
                            <Typography>Date: {getDate(referral?.referral_time).toLocaleDateString()}</Typography>
                        </Stack>
                    {/* </Container> */}
                        <Divider orientation="vertical" flexItem />
                    {/* <Container> */}
                        <Stack 
                            alignItems='center'
                            sx={{ width: '50%' }} 
                            spacing={1}
                        >    
                            
                            <Typography>Type: {referral?.code}</Typography>
                            <Typography>Time Owed: {referral?.time_owed} minutes</Typography>
                            <Typography>Code Time: {getDate(referral?.referral_time).toLocaleTimeString()}</Typography>
                            <Typography>Intake Time: {getDate(referral?.intake_time).toLocaleTimeString()}</Typography>
                            <Typography>Discharge Time: {getDate(referral?.discharge_time).toLocaleTimeString()}</Typography>
                            <Typography>Work Completed: {referral?.work_completed ? 'Y' : 'N'}</Typography>
                        </Stack>
                    {/* </Container> */}
                </Stack>
            </Box>
        </>
    )
}