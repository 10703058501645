export default interface DataGenerator {
    toJSON() : Record<string,any>
}

export enum Ethnicity {
    HispanicOrLatino = "Hispanic or Latino",
    NotHispanicOrLatino = "Not Hispanic or Latino",
    NA = "No Answer"
}

export enum Race {
    AmericanIndianOrAlaskaNative = "American Indian or Alaska Native",
    Asian = "Asian",
    BlackOrAfricanAmerican = "Black or African American",
    NativeHawaiianOrOtherPacificIslander = "Native Hawaiian or Other Pacific Islander",
    White = "White",
    NA = "No Answer"
}

export enum Gender {
    Male = "Male",
    Female = "Female",
    NonBinary = "Non-binary",
    Other = "Other",
    NA = "No Answer"
}