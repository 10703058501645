import { RootState } from "app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SessionState {
    token: string
    isLoggedIn: boolean
    user: any
}

const initialState : SessionState = {
    isLoggedIn: false,
    token: '',
    user: {}
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        putSession: (state, action: PayloadAction<SessionState>) => {
            return action.payload
        },
        endSession: () => {
            return initialState
        }
    }
})

export const { putSession, endSession } = sessionSlice.actions
export const selectSession = (state: RootState) => state.session
export default sessionSlice.reducer