import { Class } from "../types/dtos";
import ClassGenerator from "./data/class";
import { students } from './students';
import { teachers } from './teachers';
import { randomFromArray, shuffleArray } from "./utils";

export const classes = [
    { 
        ...new ClassGenerator({
            district_id: "1",
            school_id: "1",
            name: "Honors Quantum Astrophysics 101",
            period: 1,
            location: "Room 117",
        }).toJSON(),
        ...{
            id: "1",
            teachers: [randomFromArray(teachers)],
            students: shuffleArray(students).slice(
                0,
                Math.floor(Math.random() * students.length) +1
            )
        }
    },
    {
        ...new ClassGenerator({
            district_id: "1",
            school_id: "1",
            name: "AP Underwater Basket Weaving",
            period: 2,
            location: "Room 210",
        }).toJSON(),
        ...{
            id: "2",
            teachers: [randomFromArray(teachers)],
            students: shuffleArray(students).slice(
                0,
                Math.floor(Math.random() * students.length) +1
            )
        }
    },
    {
        ...new ClassGenerator({
            district_id: "1",
            school_id: "1",
            name: "A Brief History of Time",
            period: 3,
            location: "Room 212",
        }).toJSON(),
        ...{
            id: "3",
            teachers: [randomFromArray(teachers)],
            students: shuffleArray(students).slice(
                0,
                Math.floor(Math.random() * students.length) +1
            )
        }
    },
    {
        ...new ClassGenerator({
            district_id: "1",
            school_id: "1",
            name: "Taxes and How To File Them",
            period: 4,
            location: "Room 134",
        }).toJSON(),
        ...{
            id: "4",
            teachers: [randomFromArray(teachers)],
            students: shuffleArray(students).slice(
                0,
                Math.floor(Math.random() * students.length) +1
            )
        }
    },
    {
        ...new ClassGenerator({
            district_id: "1",
            school_id: "1",
            name: "Mathematical Constants 2718",
            period: 5,
            location: "Room 314",
        }).toJSON(),
        ...{
            id: "5",
            teachers: [randomFromArray(teachers)],
            students: shuffleArray(students).slice(
                0,
                Math.floor(Math.random() * students.length) +1
            )
        }
    },
    {
        ...new ClassGenerator({
            district_id: "1",
            school_id: "1",
            name: "Great Authors of the 2nd Century",
            period: 6,
            location: "Room 300",
        }).toJSON(),
        ...{
            id: "6",
            teachers: [randomFromArray(teachers)],
            students: shuffleArray(students).slice(
                0,
                Math.floor(Math.random() * students.length) +1
            )
        }
    },
    {
        ...new ClassGenerator({
            district_id: "1",
            school_id: "1",
            name: "How To Spot a Short Film",
            period: 7,
            location: "Room 121",
        }).toJSON(),
        ...{
            id: "7",
            teachers: [randomFromArray(teachers)],
            students: shuffleArray(students).slice(
                0,
                Math.floor(Math.random() * students.length) +1
            )
        }
    },
    {
        ...new ClassGenerator({
            district_id: "1",
            school_id: "1",
            name: "Ocean Exploration and Philosophy",
            period: 8,
            location: "Room 209",
        }).toJSON(),
        ...{
            id: "8",
            teachers: [randomFromArray(teachers)],
            students: shuffleArray(students).slice(
                0,
                Math.floor(Math.random() * students.length) +1
            )
        }
    },
    {
        ...new ClassGenerator({
            district_id: "1",
            school_id: "1",
            name: "Gym",
            period: 9,
            location: "Room 115",
        }).toJSON(),
        ...{
            id: "9",
            teachers: [randomFromArray(teachers)],
            students: shuffleArray(students).slice(
                0,
                Math.floor(Math.random() * students.length) +1
            )
        }
    }



] as unknown as Class[]