import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { PlayCircleFilledOutlined, PauseCircleOutlined } from '@mui/icons-material'
import { useAppDispatch } from 'app/hooks';
import { BehaviorCodeTimer } from 'dtos/timers';
import { putTimer } from 'features/timers/timersSlice';
import { togglePause } from 'functions/timers';

export interface BasicBehaviorCodeTimerControlProps {
    behaviorCodeTimer: BehaviorCodeTimer
}
export const BasicBehaviorCodeTimerControl = ({ behaviorCodeTimer } : BasicBehaviorCodeTimerControlProps) => {
    const dispatch = useAppDispatch()
    const [ paused, setPaused ] = useState<boolean>(behaviorCodeTimer.paused)

    useEffect(() => {
        if (paused !== behaviorCodeTimer.paused) {
            let timerUpdate = togglePause(behaviorCodeTimer)
            dispatch(putTimer(timerUpdate))
        }
    }, [paused, behaviorCodeTimer, dispatch])

    return (
        <IconButton
            sx={{ position: 'absolute', bottom: '0', right: '0'}}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setPaused(!paused)
            }}
        >
            {
                behaviorCodeTimer.paused 
                ? (<PlayCircleFilledOutlined />)
                : (<PauseCircleOutlined />)
            }
        </IconButton>
    )
}