import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Box, Stack } from "@mui/material"
import { Login } from './Login'
import { NavigationBar } from "../features/navigation/NavigationBar";
import { selectSession } from "features/auth/authSlice";
import { LoginPage } from "./LoginPage";

export function Layout() {
    const { isLoggedIn } = useSelector(selectSession)

    if (!isLoggedIn) return <LoginPage />

    return (
        <Box height='100vh' width='100vw' sx={{ display: 'flex', flexDirection: 'column' }}>
            <NavigationBar />
            <Box sx={{ flexGrow: 1 }}>
                <Outlet/>
            </Box>
        </Box>
    )
}