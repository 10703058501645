import { Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useCountdown } from 'react-time-sync';
import { epochSecondsToEpoch, secondsToRemainingTime, RemainingTime, getRemainingDisplayTime } from 'functions/time';
import { TimerProps } from './types';
import TimerContext from 'contexts/BehaviorTimerContext';


export const Countdown = ({ behaviorCodeTimer }: TimerProps) => {
    const { active, setActive} = useContext(TimerContext)
    const secondsLeft = useCountdown({ until: epochSecondsToEpoch(behaviorCodeTimer.timer_end_epoch_seconds) })
    const [ remainingTime, setRemainingTime ] = useState<RemainingTime>(secondsToRemainingTime(secondsLeft));

    useEffect(() => {
        if (!behaviorCodeTimer.paused) {
            if (secondsLeft === 0) {
                setActive(false)
            }
    
            setRemainingTime(
                secondsToRemainingTime(secondsLeft)
            )
        }
    }, [secondsLeft, setActive, behaviorCodeTimer.paused])

      
    return (
        <Typography 
            variant='h3'
            color={ active ? behaviorCodeTimer.color : '#4CAF50' }
        >
                {getRemainingDisplayTime(remainingTime)}
        </Typography> 
    )
}