import { createTheme, ThemeOptions } from "@mui/material";


export const themeOptions: ThemeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#2492D9',
      light: '#4FA7E0',
      dark: '#196697',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#eb942d',
      light: '#EFA957',
      dark: '#A4671F',
      contrastText: '#000000'
    },
    error: {
      main: '#F44336',
      light: '#E57373',
      dark: '#D32F2F',
      contrastText: '#000000'
    },
    success: {
      main: '#4CAF50',
      light: '#81C784',
      dark: '#388E3C',
      contrastText: '#000000'
    },
    warning: {
      main: '#FF9800',
      light: '#FFB74D',
      dark: '#F57C00',
      contrastText: '#000000'
    },
    info: {
      main: '#2196f3',
      light: '#64B5F6',
      dark: '#1976D2',
      contrastText: '#FFFFFF'
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF'
    }
  },
});