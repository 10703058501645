import React from 'react'
import { BarChart as ReBarChart, Bar as ReBar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ChartProps } from './types';

export interface BarProps {
    dataKey: string,
    stackId?: string,
    fillColor? : string
    strokeColor? : string
}

export interface BarChartProps extends ChartProps {
    bars: BarProps[]
}

export const BarChart = ({ data, bars, xAxisProps = {}, yAxisProps = {}, tooltipContent } : BarChartProps) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <ReBarChart width={400} height={400} data={data}>   
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Tooltip content={ tooltipContent ? tooltipContent : undefined}/>
                <XAxis  {...xAxisProps} />
                <YAxis {...yAxisProps} />
                {
                    bars.map((b : BarProps) => (
                        <ReBar 
                            key={Math.random().toString().slice(2, 10)}
                            dataKey={b.dataKey}
                            stroke={b.strokeColor || "#2492D9"} 
                            fill={b.fillColor || "#2492D9"}
                            stackId={b.stackId}
                        />
                    ))
                }
            </ReBarChart>
        </ResponsiveContainer>
    )
}