import { TimerType } from "components/timers/types";
import { BehaviorCodeTimer } from "dtos/timers";
import { getCurrentEpochSeconds } from 'functions/time';

export const getTimerDisplayText = (type: TimerType) : string => {
    switch (type) {
        case TimerType.DonutFill:
            return "Donut Fill"
        case TimerType.LinearFill:
            return "Linear Fill"
        default:
            return "Countdown"
    }
}

export const togglePause = (timer: BehaviorCodeTimer) : BehaviorCodeTimer => {
    let paused = !timer.paused
    let currentEpochSeconds = getCurrentEpochSeconds()
    let update : Partial<BehaviorCodeTimer> = { paused }

    if (paused) {
        let elapsedSeconds = currentEpochSeconds - timer.timer_start_epoch_seconds
        update.timer_paused_epoch_seconds = currentEpochSeconds
        update.seconds_completed = timer.seconds_completed + elapsedSeconds
    } else {
        let secondsRemaining = timer.seconds_owed - timer.seconds_completed
        update.timer_start_epoch_seconds = currentEpochSeconds
        update.timer_end_epoch_seconds = currentEpochSeconds + secondsRemaining
    }

    let newTimer = { ...timer, ...update }

    return newTimer
}