import f from 'faker'
import DataGenerator from './types'
import TeacherGenerator from './teacher'
import StudentGenerator from './student'
import { ReferralCode } from "../../types/dtos"
import { randomFromEnum } from './lib/random_from_enum'
import { asInserted } from './lib/as_inserted'
import { getRandomCreateTime, getRandomDischargeTime, getRandomStartTime } from '../utils'

export interface ReferralGeneratorOpts {
    district_id?: string
    school_id? : string
    teacher? : Record<string,any>
    referrer? : Record<string,any>
    student? : Record<string,any>
    class? : Record<string, any>
    code? : ReferralCode
    time_owed? : number
    location? : string
    escort_required? : boolean
    intake_time? : number
    discharge_time? : number
    discharged? : boolean
    work_completed? : boolean
}

export default class ReferralGenerator implements DataGenerator {
    public district_id: string
    public school_id : string
    public teacher : Record<string,any>
    public referrer : Record<string,any>
    public student : Record<string,any>
    public class : Record<string, any> | undefined
    public code : string
    public time_owed : number
    public location : string
    public escort_required : boolean
    public intake_time? : number
    public discharge_time? : number
    public discharged? : boolean
    public work_completed? : boolean
    public referral_time? : number
    public referral_date_string? : string
    public referral_time_string? : string


    constructor(opts? : ReferralGeneratorOpts) {
        const time_owed = f.datatype.number({ min: 10, max: 30 })
        const referral_time = getRandomCreateTime()
        const referral_date = new Date(referral_time * 1000)
        const intake_time = getRandomStartTime(referral_time)
        const discharge_time = getRandomDischargeTime(intake_time, time_owed)

        this.district_id = opts?.district_id || Math.random().toString().slice(2,7)
        this.school_id = opts?.school_id || Math.random().toString().slice(2,7)
        this.teacher = opts?.teacher || asInserted(new TeacherGenerator(), { full_name: true })
        this.referrer = opts?.referrer || asInserted(new TeacherGenerator(), { full_name: true })
        this.student = opts?.student || asInserted(new StudentGenerator(), { full_name: true })
        this.code = opts?.code || randomFromEnum(ReferralCode)
        this.time_owed = time_owed
        this.class = opts?.class 
        this.escort_required = opts?.escort_required || Math.random() > 0.5
        this.location = opts?.location || `Room ${f.datatype.number({min:1, max:4})}0${f.datatype.number({min: 0, max: 9})}`
        this.referral_time = referral_time
        this.referral_date_string = referral_date.toLocaleDateString()
        this.referral_time_string = referral_date.toLocaleTimeString()
        this.intake_time = intake_time
        this.discharge_time = discharge_time
        this.discharged = true
        this.work_completed = Math.random() > 0.5
    }

    toJSON(): Record<string, any> {
        return {
            district_id: this.district_id,
            school_id: this.school_id,
            teacher: this.teacher,
            referrer: this.referrer,
            student: this.student,
            class: this.class,
            code: this.code,
            time_owed: this.time_owed,
            escort_required: this.escort_required,
            location: this.location,
            referral_time: this.referral_time,
            referral_date_string: this.referral_date_string,
            referral_time_string: this.referral_time_string,
            intake_time: this.intake_time,
            discharge_time: this.discharge_time,
            discharged: this.discharged,
            work_completed: this.work_completed
        }
    }
}