import React from 'react'
import { Container } from '@mui/material';
import { BasicBehaviorCodeGrid } from 'features/basic/behavior_codes/BasicBehaviorCodeGrid';
import { TimeProvider } from "react-time-sync";
import { BasicBehaviorCodeData } from 'features/basic/behavior_codes/BasicBehaviorCodeData';


export const BasicQuickRoom = () => {
    return (
        <TimeProvider>
            <Container
                sx={{ 
                    minWidth: '100vw',
                    minHeight: '100%',
                    bgcolor: '#ffffff',
                }}
            >
                <BasicBehaviorCodeData />
                <BasicBehaviorCodeGrid />
            </Container>
        </TimeProvider>
    )
}