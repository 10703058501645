import React from 'react'
import moment from 'moment';
import { Label } from 'recharts';
import { LineChart, LineChartProps } from './LineChart'
import { getEndOfToday, getStartOfToday } from '../filters/utils';
import { SingleDayLineChartTooltip } from './tooltips/SingleDayLineChartTooltip';

const getYDomainMax = (dataMax : number) : number => {
    return dataMax + Math.floor(0.1*dataMax) + 1
}

export const SingleDayLineChart = ({data, lines } : LineChartProps) => {
    return (
        <LineChart 
            title="TODO"
            data={data}
            lines={lines}
            xAxisProps={{
                dataKey: 'referral_time',
                type: 'number',
                domain: [
                    Math.floor(getStartOfToday().getTime() / 1000),
                    Math.floor(getEndOfToday().getTime() / 1000)
                ],
                name: 'Time',
                dy: 25,
                height: 100,
                tickFormatter: (epochSeconds) => moment(epochSeconds * 1000).format("HH:mm"),
            }}
            yAxisProps={{
                type: "number",
                domain: [0, getYDomainMax],
                allowDecimals: false,
                // @ts-ignore
                label: (
                    <Label angle={-90} value='Time Owed' position='insideLeft' style={{textAnchor: 'middle'}}  />
                )
            }}
            tooltipContent={<SingleDayLineChartTooltip />}
        />
    )
}