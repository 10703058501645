import React from 'react'
import { Container } from '@mui/material';
import { DischargedReferralGrid } from '../features/referral/discharged/DischargedReferraGrid'


export const Codes = () => {
    return (
        <Container
            sx={{ 
                minHeight: '100vh',
                bgcolor: '#ffffff',
                boxShadow: 5,
            }}
        >
            <DischargedReferralGrid />
        </Container>
    )
}