import DataGenerator from '../types';

interface AsInsertedOpts {
    full_name?: boolean
}
export const asInserted = (generator: DataGenerator, opts?: AsInsertedOpts) => {
    let insertedProperties : any = { id: Math.random().toString().slice(2,7) }

    if (opts?.full_name) {
        insertedProperties.full_name = "full_name"
    }

    return { ...generator.toJSON(), ...insertedProperties }
}