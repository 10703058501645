import React from 'react'
import { DailyStats } from './stats/Daily';
import {Box, Container, Typography, Stack } from '@mui/material'
import { useSelector } from 'react-redux';
import { selectDischargedReferrals } from '../referral/referralsSlice';
import { asArray } from '../filters/utils';
import { Referral } from '../../types/dtos'
import { FilterableData } from './FilterableData';

export const Statistics = () => {
    const dischargedReferrals = useSelector(selectDischargedReferrals)

    return (
        <Stack 
            direction='column'
            spacing={5}
            sx={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
            <Box 
                width='100%'
                flexDirection='column'
                boxShadow={2}
                sx={{ borderRadius: 2 }}
            >
                <Container sx={{ padding: 2 }}>
                    <Typography variant='h3'>Quick Room Snapshot</Typography>
                    <DailyStats />
                </Container>
            </Box>

            <Box 
                width='100%'
                flexDirection='column'
                boxShadow={2}
                sx={{ borderRadius: 2, paddingBottom: 2 }}
            >
                <Container sx={{ padding: 2}}>
                    <Typography paddingTop={2} variant="h3">Insights</Typography>
                    <FilterableData />
                </Container>
            </Box>            
        </Stack>
    )
}