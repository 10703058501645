import React from 'react';

export enum PlanType {
    Basic = 0,
    Enterprise = 1
}

const PlanContext = React.createContext({
    plan: PlanType.Basic,
    setPlan: (plan: PlanType) => {}
})

export const PlanProvider = PlanContext.Provider
export default PlanContext