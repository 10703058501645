import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from 'pages/Layout';
import { Classes } from 'pages/Classes';
import { Class } from 'pages/Class'
import { EnterpriseQuickRoom } from 'pages/EnterpriseQuickRoom';
import { Codes } from 'pages/Codes';
import { Dashboard } from 'pages/Dashboard';

export function EnterpriseRouter() {
  return (
    <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout />}>
                  <Route index element={<Dashboard />} />
                  <Route path="/classes" element={<Classes />} />
                  <Route path="/classes/:classId" element={<Class />} />
                  <Route path="/quick-room" element={<EnterpriseQuickRoom />} />
                  <Route path="/codes" element={<Codes />} /> 
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
          </Routes>
    </BrowserRouter>
   );
}

