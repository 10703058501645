import { /*matchPath,*/ useLocation } from 'react-router-dom';

// matches parent paths
export default function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();
    let basePath = pathname.split("/")

    if (basePath.length < 2) {
      return "/"
    }

    return "/" + basePath[1]
    // for (let i = 0; i < patterns.length; i += 1) {
    //   const pattern = patterns[i];
    //   const possibleMatch = matchPath(pattern, pathname);
    //   if (possibleMatch !== null) {
    //     return possibleMatch;
    //   }
    // }
  
    // return null
  }