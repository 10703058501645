import { useQueryParams } from "hooks/useQueryParams"
import React, { useEffect } from "react"
import { Container, Box, Avatar, Typography, Grid, TextField, Link, Button } from "@mui/material"
import { LockOutlined } from "@mui/icons-material"
import { useAppDispatch } from "app/hooks"
import { putSession } from "features/auth/authSlice"


export const LoginPage = () => {
    const dispatch = useAppDispatch()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        
        const email = data.get('email')
        const password = data.get('password')
        
        console.log({
          email,
          password,
        });

        if (email === "user@basic.demo" && password === "basicplandemo") {
            dispatch(putSession({
                isLoggedIn: true,
                token: '',
                user: {}
            }))
        } else {
            alert("Invalid email or password")
        }
      };

    return (
        <Container>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        />
                    </Grid>
                    
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        >
                        Login
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}