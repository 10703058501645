import React, { useContext } from 'react';
import PlanContext, { PlanType } from 'contexts/PlanContext'

export interface PlanDependentProps {
    basic: JSX.Element
    enterprise: JSX.Element
}

export const PlanDependent = ( { basic, enterprise } : PlanDependentProps) => {
    const { plan } = useContext(PlanContext)

    return (
        plan === PlanType.Enterprise
        ? enterprise
        : basic
    )
}