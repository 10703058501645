import { BehaviorCodeTimer } from "dtos/timers"

export interface TimerProps {
    behaviorCodeTimer : BehaviorCodeTimer
}

export enum TimerType {
    NumericCountdown = 0,
    DonutFill = 1,
    LinearFill = 2,
    DonutDrain = 3,
    LinearDrain = 4
}

export enum TimerUnits {
    Seconds = 0,
    Minutes = 1,
    Hours = 2
}