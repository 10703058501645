import React from 'react';
import { Student } from '../../../types/dtos';
import { Stack, Typography, Box, Avatar } from '@mui/material';
export interface ClassStudentProps {
    student: Student
}

const studentInitials = (student: Student) => {
    let first = student?.first_name?.slice(0,1) || ""
    let last = student?.last_name?.slice(0,1) || ""

    return `${first.toUpperCase()}${last.toUpperCase()}`
}

export default function ClassStudent({ student } : ClassStudentProps) {
    return (
        <Box 
            display='flex'
            flexDirection='column'
            justifyContent='center'
            sx={{ 
                width: 200,
                height: 200,
                cursor: 'pointer', 
                boxShadow: 2, 
                '&:hover': {
                    boxShadow: 5
                }
            }}
        >   
            <Stack alignItems='center' justifyContent='center' direction='column' spacing={2}>
                <Avatar 
                    src={student?.image}
                    sx={{ width: 75, height: 75 }}
                >
                    {studentInitials(student)}
                </Avatar>
                <Typography 
                    align='center'
                >
                    {student.full_name}
                </Typography>
            </Stack>

                
        </Box>
    )
}