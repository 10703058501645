import React, { useEffect, useState } from 'react';
import { Stack, DialogContent, DialogTitle, DialogActions, FormControlLabel, Switch, TextField } from '@mui/material';
import { removeFromActive, addToDischarged, ActiveReferral } from '../referralsSlice';
import { useAppDispatch } from '../../../app/hooks';
import { Referral } from "../../../types/dtos";
import {CancelButton} from '../../../components/CancelButton'
import {SubmitButton} from '../../../components/SubmitButton'

export interface DischargeReferralProps {
    referral: ActiveReferral
    setOpen: Function
}

export const DischargeReferral = ({ referral, setOpen } : DischargeReferralProps) => {
    const [ readyToDischarge, setReadyToDischarge ] = useState<boolean>(false)
    const [ work_completed, setWorkCompleted ] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (readyToDischarge) {
            let now = Math.floor(Date.now() / 1000)
            
            const dischargedReferral = {
                ...referral,
                ...{
                    work_completed: work_completed,
                    discharged: true,
                    discharge_time: now,
                    updated_at: now
                }
            }

            dispatch(addToDischarged(dischargedReferral))
            dispatch(removeFromActive(referral))
            setOpen(false)
        }
    }, [readyToDischarge])

    return (
        <>
            <DialogTitle sx={{ margin: "auto", textAlign: 'center' }}>Discharge Student</DialogTitle>
            
            <DialogContent sx={{ minWidth: '15rem'}}>
                <Stack
                    spacing={5} 
                    direction='column'
                >
                    <TextField 
                        id="student"
                        label="Student"
                        defaultValue={referral?.student?.full_name}
                        variant="standard"
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                            readOnly: true
                        }}
                    />

                    <FormControlLabel 
                        value="bottom"
                        label="Work Completed"
                        labelPlacement='top'
                        control={
                            <Switch
                                color='secondary' 
                                checked={work_completed}
                                onChange={(e) => { setWorkCompleted(e.target.checked) }}
                            />
                        }
                    />
                </Stack>
            </DialogContent>

            <DialogActions >
                <Stack 
                    direction="row"
                    sx={{ justifyContent: 'space-between', width: '100%' }}
                >
                    <CancelButton onClick={() => { setOpen(false) }}>
                        Cancel
                    </CancelButton>

                    <SubmitButton onClick={() => { setReadyToDischarge(true) } }>
                        Discharge
                    </SubmitButton>
                </Stack>
            </DialogActions>
        </>
    )
}  