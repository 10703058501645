import React from 'react';
import { TextField } from '@mui/material'
import { DateTimePicker } from '@mui/lab';

export interface DateTimeFilterProps {
    initialDate?: Date
    label?: string
    cb?: Function
}
export const DateTimeFilter = ({ initialDate = new Date(), label = "Date & Time Picker", cb }: DateTimeFilterProps) => {
    const [value, setValue] = React.useState<Date | null>(initialDate);

    const handleChange = (newValue: Date | null) => {
        if (newValue) {
            // @ts-ignore
            // momentjs conversion based on LocaleProvider
            setValue(newValue.toDate());

            if (cb) {
                // @ts-ignore
                // momentjs conversion based on LocaleProvider
                cb(newValue.toDate()) 
            }
        }
    };

    return (
        <DateTimePicker
            label={label}
            value={value}
            onChange={handleChange}
            clearable={false}
            renderInput={(params: any) => <TextField {...params} />}
        />
    )
}