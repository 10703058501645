import { Container } from '@mui/material';
import React from 'react';

import { Statistics } from '../features/dashboard/Statistics';

export function Dashboard() {
  return (
    <Container
      sx={{ 
        minHeight: '100vh',
        bgcolor: '#ffffff',
        boxShadow: 5,
      }}
    >
      <Statistics />
    </Container>
  );
}
