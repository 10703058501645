import f from 'faker'
import { Ethnicity, Gender, Race, Student } from "../types/dtos";
import { randomFromEnum } from './utils';

export const students = [
    {
        id: "1",
        full_name: "Rose Tyler",
        first_name: "Rose",
        last_name: "Tyler",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://upload.wikimedia.org/wikipedia/en/7/74/Rose_Tyler.jpg"
    },
    {
        id: "2",
        full_name: "Martha Jones",
        first_name: "Martha",
        last_name: "Jones",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://www.doctorwhotv.co.uk/wp-content/uploads/martha-series-4-tdd.jpg"
    },
    {
        id: "3",
        full_name: "Donna Noble",
        first_name: "Donna",
        last_name: "Noble",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/800/Donna-Noble.Doctor-Who.webp"
    },
    {
        id: "4",
        full_name: "Amy Pond",
        first_name: "Amy",
        last_name: "Pond",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://static3.srcdn.com/wordpress/wp-content/uploads/2020/03/Amy-Pond.png?q=50&fit=crop&w=960&h=500&dpr=1.5"
    },
    {
        id: "5",
        full_name: "Rory Pond",
        first_name: "Rory",
        last_name: "Pond",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/2800/Rory-Williams.Doctor-Who.webp"
    },
    {
        id: "6",
        full_name: "Clara Oswald",
        first_name: "Clara",
        last_name: "Oswald",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://www.doctorwhotv.co.uk/wp-content/uploads/clara-itd-portrait.jpg"
    },
    {
        id: "7",
        full_name: "River Song",
        first_name: "River",
        last_name: "Song",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://ichef.bbci.co.uk/images/ic/1200x675/p03c4mw8.jpg"
    },
    {
        id: "8",
        full_name: "Wilfred Mott",
        first_name: "Wilfred",
        last_name: "Mott",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://comicvine.gamespot.com/a/uploads/scale_small/1/18154/4769279-7234667804-latest"
    },
    {
        id: "9",
        full_name: "Billy Potts",
        first_name: "Billy",
        last_name: "Potts",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://i.ytimg.com/vi/lnh0VwshPc8/maxresdefault.jpg"
    },
    {
        id: "10",
        full_name: "Jack Harkness",
        first_name: "Jack",
        last_name: "Harkness",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://images.immediate.co.uk/production/volatile/sites/3/2020/01/captain-jack-harkness-6625b23.jpg?quality=90&resize=768,574"
    },
    {
        id: "11",
        full_name: "Nardole",
        first_name: "Nardole",
        last_name: "Nardole",
        grade: f.datatype.number({ min: 9, max: 12 }),
        gender: randomFromEnum(Gender),
        race: randomFromEnum(Race),
        ethnicity: randomFromEnum(Ethnicity),
        image: "https://ichef.bbci.co.uk/images/ic/1200x675/p04zmzw4.jpg"
    }

] as Student[]