import React, { useEffect, useState } from 'react';
import { Container, DialogTitle, DialogContent, TextField, Stack, FormLabel } from '@mui/material';
import { useAppDispatch } from 'app/hooks';
import { putTimer } from 'features/timers/timersSlice';
import { BehaviorCodeTimer } from 'dtos/timers';
import { DialogActions } from 'components/buttons/DialogActions';
import { TimeOwedInput } from 'components/time_owed/TimeOwedInput';

interface EditBasicBehaviorCodeDialogProps {
    setOpen: Function
    behaviorCodeTimer: BehaviorCodeTimer
}

export const EditBasicBehaviorCodeDialog = ({ setOpen, behaviorCodeTimer } : EditBasicBehaviorCodeDialogProps) => {
    
    const dispatch = useAppDispatch()
    const [label, setLabel] = useState<string>(behaviorCodeTimer.timer_label)
    const [hoursOwed, setHoursOwed] = useState<number>(Math.floor((behaviorCodeTimer.seconds_owed / (60 * 60)) % 60))
    const [minutesOwed, setMinutesOwed] = useState<number>(Math.floor((behaviorCodeTimer.seconds_owed / (60)) % 60))
    const [secondsOwed, setSecondsOwed] = useState<number>(Math.floor(behaviorCodeTimer.seconds_owed % 60))

    const [ ready, setReady ] = useState<boolean>(false)

    useEffect(() => {
        if (ready) {
            let seconds_owed = (hoursOwed * 60 * 60) + (minutesOwed * 60) + secondsOwed
            let secondsOwedDifference = seconds_owed - behaviorCodeTimer.seconds_owed
            let timer_end_epoch_seconds = behaviorCodeTimer.timer_end_epoch_seconds + secondsOwedDifference

            let changes = { 
                timer_label: label, 
                seconds_owed,
                timer_end_epoch_seconds
            }

            let newTimer = { ...behaviorCodeTimer, ...changes}

            dispatch(putTimer(newTimer))
            setOpen(false)
        }
    }, [ready])


    return (
        <Container sx={{width: '25rem'}}> 
            <DialogTitle sx={{ margin: "auto", textAlign: 'center' }}>Modify Timer</DialogTitle>

            <DialogContent>
                <Stack direction="column" spacing={5}>
                    <TextField 
                        id="student"
                        label="Student Name"
                        value={label}
                        variant="standard"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        onChange={(e) => { 
                            setLabel(e.target.value) 
                        }}
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                        }}
                    />

                    <FormLabel>Modify Time Owed</FormLabel>
                    <TimeOwedInput 
                        onHoursChange={setHoursOwed}
                        onMinutesChange={setMinutesOwed}
                        onSecondsChange={setSecondsOwed}
                        hoursOwed={hoursOwed}
                        minutesOwed={minutesOwed}
                        secondsOwed={secondsOwed}
                    />
                </Stack>
            </DialogContent>

            <DialogActions
                onCancel={(e) => { 
                    e.preventDefault()
                    e.stopPropagation()
                    setOpen(false) 
                }}
                onSubmit={(e) => { 
                    e.preventDefault()
                    e.stopPropagation()
                    setReady(true) 
                }}
                submitText="Modify"
            />
        </Container>
    )
}