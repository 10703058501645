import React from 'react'
import { PlanDependent } from 'components/logical/PlanDependent';
import { BasicRouter} from 'routes/BasicRouter';
import { EnterpriseRouter } from 'routes/EnterpriseRouter';

export default function App() {
  return (
    <PlanDependent 
      basic={<BasicRouter />}
      enterprise={<EnterpriseRouter />}
    />
   );
}

