import f from 'faker';
import { Race, Ethnicity, Gender } from './types'
import DataGenerator from './types'
import { randomFromEnum } from './lib/random_from_enum';
import { grade } from './lib/grade';

export interface StudentGeneratorOpts {
    first_name?: string,
    middle_name?: string,
    last_name?: string,
    gender?: Gender,
    ethnicity?: Ethnicity,
    race?: Race,
    school_id?: string,
    district_id?: string
    grade?: number
}

export default class StudentGenerator implements DataGenerator {
    public first_name: string
    public middle_name: string
    public last_name: string
    public gender: string
    public ethnicity: string
    public race: string
    public school_id: string
    public district_id: string
    public grade: number

    constructor(opts? : StudentGeneratorOpts) {
        this.first_name = opts?.first_name || f.name.firstName()
        this.middle_name = opts?.middle_name || f.name.middleName().toUpperCase()
        this.last_name = opts?.last_name || f.name.lastName()
        this.gender = opts?.gender || randomFromEnum(Gender)
        this.race = opts?.race || randomFromEnum(Race)
        this.ethnicity = opts?.ethnicity || randomFromEnum(Ethnicity)
        this.school_id = opts?.school_id || Math.random().toString().slice(2,7)
        this.district_id = opts?.district_id || Math.random().toString().slice(2,7)
        this.grade = opts?.grade || grade()
    }

    toJSON() {
        return {
            first_name: this.first_name,
            middle_name: this.middle_name,
            last_name: this.last_name,
            gender: this.gender,
            ethnicity: this.ethnicity,
            race: this.race,
            school_id: this.school_id,
            district_id: this.district_id,
            grade: this.grade
        }
    }
}