import React from 'react'
import { Grid } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { BasicBehaviorCodeGridItem } from './BasicBehaviorCodeGridItem';
import { selectTimers } from 'features/timers/timersSlice';
import { BehaviorCodeTimer } from 'dtos/timers';

export const BasicBehaviorCodeGrid = () => {
    const timers = useAppSelector(selectTimers)

    return (
        <Grid 
            container
            columns={5} 
            spacing={2}
            wrap="wrap"
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            paddingTop='2rem'
        >
            {
                Object.entries(timers).map(
                    // @ts-ignore
                    (value: [string, BehaviorCodeTimer], index: number) => {
                        return <BasicBehaviorCodeGridItem key={value[1].behavior_code_id} behaviorCodeTimer={value[1]} />
                    }
                )
            }
        </Grid>
    )
}