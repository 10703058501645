import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Referral } from "../../types/dtos";
import { dreferrals } from '../../demo/referrals';
import { TimerType } from './timers/types';

interface ReferralsState {
    pending: Record<string, Referral>
    active: Record<string, ActiveReferral>
    discharged: Record<string, Referral>
}

export interface ActiveReferral extends Referral {
    timer: TimerType
}

const initialState: ReferralsState = {
    pending: {},
    active: {},
    discharged: dreferrals
}

export const referralsSlice = createSlice({
    name: 'referrals',
    initialState,
    reducers: {
        addToPending: (state, action: PayloadAction<Referral>) => {
            if (!action.payload.id) return
            state.pending[action.payload.id] = action.payload
        },
        removeFromPending: (state, action: PayloadAction<Referral>) => {
            if (!action.payload.id) return
            delete state.pending[action.payload.id]
        },
        addToActive: (state, action: PayloadAction<ActiveReferral>) => {
            if (!action.payload.id) return
            state.active[action.payload.id] = action.payload
        },
        removeFromActive: (state, action: PayloadAction<Referral>) => {
            if (!action.payload.id) return
            delete state.active[action.payload.id]
        },
        addToDischarged: (state, action: PayloadAction<Referral>) => {
            if (!action.payload.id) return
            state.discharged[action.payload.id] = action.payload
        },
        removeFromDischarged: (state, action: PayloadAction<Referral>) => {
            if (!action.payload.id) return
            delete state.discharged[action.payload.id]
        },
        reset: (state) => {
            state = {...initialState }
        }
    }
})

export const { addToPending, addToActive, addToDischarged, removeFromPending, removeFromActive, removeFromDischarged } = referralsSlice.actions;
export const selectReferrals = (state: RootState) => state.referrals
export const selectPendingReferrals = (state: RootState) => state.referrals.pending
export const selectActiveReferrals = (state: RootState) => state.referrals.active
export const selectDischargedReferrals = (state: RootState) => state.referrals.discharged

export default referralsSlice.reducer