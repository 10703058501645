import React, { useState, useEffect } from 'react'
import { useAppDispatch } from 'app/hooks';
import { IconButton, Menu } from '@mui/material';
import { ColorLensOutlined } from '@mui/icons-material'
import { BehaviorCodeTimer } from 'dtos/timers';
import { CirclePicker } from "react-color";
import { putTimer } from 'features/timers/timersSlice';

interface BasicBehaviorCodeTimerColorPickerProps {
    behaviorCodeTimer : BehaviorCodeTimer
}

export const BasicBehaviorCodeTimerColorPicker = ({ behaviorCodeTimer }: BasicBehaviorCodeTimerColorPickerProps) => {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [ open, setOpen] = useState<boolean>(false);
    const [ color, setColor ] = useState<string>(behaviorCodeTimer.color)

    useEffect(() => {
        let newTimer = { ...behaviorCodeTimer, ...{ color }}
        dispatch(putTimer(newTimer))
    }, [color])

    return (
        <>
            <IconButton
                sx={{ position: 'absolute', top: '0', left: '0'}}
                onClick={(e) => { 
                    e.preventDefault()
                    e.stopPropagation()
                    setAnchorEl(e.currentTarget)
                    setOpen(true) 
                }}
            >
                <ColorLensOutlined />
            </IconButton>

            <Menu
                id="long-menu"
                MenuListProps={{ 'aria-labelledby': 'long-button',}}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={open}
                onClose={(e) => {
                    // @ts-ignore
                    e.preventDefault()
                    // @ts-ignore
                    e.stopPropagation()
                    setAnchorEl(null)
                    setOpen(false)
                }}
                PaperProps={{
                style: {
                    maxHeight: 48 * 4.5 * 2,
                    width: '25ch',
                    zIndex: 9999
                },
                }}
            >
                <CirclePicker 
                    color={color} 
                    onChange={(color, e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        // @ts-ignore
                        setColor(color.hex)
                    }} 
                    onChangeComplete={(c, e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    colors={[
                        "#f44336",
                         "#e91e63",
                         "#9c27b0",
                         "#673ab7",
                         "#3f51b5",
                         "#2492D9",
                         "#03a9f4",
                         "#00bcd4",
                         "#009688",
                         "#4caf50",
                         "#8bc34a",
                         "#cddc39",
                         "#ffeb3b",
                         "#ffc107",
                         "#ff9800",
                         "#ff5722",
                         "#795548",
                         "#607d8b"
                        ]
                    }
                />
            </Menu>
        </>
    )
}