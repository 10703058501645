import React from 'react'
import { Box, Typography } from '@mui/material';
import { Label } from 'recharts';
import { getYDomainMax } from './utils'
import { BarChart, BarChartProps } from './BarChart'

export const PropertyGroupedBarChart = ({ title, data, bars, xAxisProps, yAxisProps } : BarChartProps) => {
 return (
    <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        width='100%'
        height='30rem'
    >
        <Typography ml={2} variant="h5">{title}</Typography>
        <BarChart
            title="TODO"
            data={data}
            bars={bars}
            xAxisProps={{
                dataKey: xAxisProps?.dataKey || 'property',
                interval:xAxisProps?.interval ||  0, 
                type: xAxisProps?.type || 'category',
                domain: xAxisProps?.domain || ['auto', 'auto'],
                dy: xAxisProps?.dy || 30,
                height: xAxisProps?.height || 100,
                angle: xAxisProps?.angle || 45,
                tickFormatter: xAxisProps?.tickFormatter
            }}
            yAxisProps={{
                type: yAxisProps?.type || 'number',
                domain: yAxisProps?.domain || [0, getYDomainMax],
                allowDecimals: yAxisProps?.allowDecimals || false,
                // @ts-ignore
                label: yAxisProps?.label || (
                    <Label angle={-90} value='Number of Codes' position='insideLeft' style={{textAnchor: 'middle'}}  />
                )
            }}
        />
    </Box>   
 )
}