export interface BuildInfo {
    version: string
    build: string
    buildTime: string
}

export interface Class {
    id: string
    school_id: string
    district_id: string
    name: string
    period: number
    location: string
    teachers: Teacher[]
    students: Student[]
}

export interface ReadReferral {
    id: string
    district_id: string
    school_id: string
    teacher: Teacher
    referrer: Teacher
    student: Student
    code: ReferralCode
    time_owed: number
    discharged: boolean
    escort_required: boolean
    work_completed: boolean
    referral_time: number
    referral_date_string: string
    referral_time_string: string
    location: string
    // Fields that get updated are not guaranteed to exist
    intake_time?: number
    discharge_time?: number
    updated_at?: number
}


export interface Referral {
    id?: string
    district_id?: string
    school_id?: string
    teacher?: Teacher
    referrer?: Teacher
    student?: Student
    class?: Partial<Class>
    code?: ReferralCode
    time_owed?: number
    discharged?: boolean
    escort_required?: boolean
    work_completed?: boolean
    referral_time?: number
    referral_date_string?: string
    referral_time_string?: string
    location?: string
    // Fields that get updated are not guaranteed to exist
    intake_time?: number
    discharge_time?: number
    updated_at?: number
}

export interface Student {
    id: string
    image?: string
    school_id?: string
    district_id?: string
    full_name?: string
    first_name?: string
    middle_name?: string
    last_name?: string
    gender?: Gender
    ethnicity?: Ethnicity
    race?: Race
    grade?: number
}

export interface Teacher {
    id?: string,
    image?: string,
    full_name?: string,
    first_name?: string,
    middle_name?: string,
    last_name?: string,
    gender?: Gender,
    ethnicity?: Ethnicity,
    race?: Race,
    school_id?: string,
    district_id?: string
}

export enum Ethnicity {
    HispanicOrLatino = "Hispanic or Latino",
    NotHispanicOrLatino = "Not Hispanic or Latino",
    NA = "No Answer"
}

export enum Race {
    AmericanIndianOrAlaskaNative = "American Indian or Alaska Native",
    Asian = "Asian",
    BlackOrAfricanAmerican = "Black or African American",
    NativeHawaiianOrOtherPacificIslander = "Native Hawaiian or Other Pacific Islander",
    White = "White",
    NA = "No Answer"
}

export enum ReferralCode {
    Aggression = "AGGR",
    PropertyDestruction = "PDST",
    NonCompliance = "NCOM",
    DisruptiveBehavior = "DISR",
    Elopement = "ELOP",
    Language = "LANG"
}

export enum Gender {
    Male = "Male",
    Female = "Female",
    NonBinary = "Non-binary",
    Other = "Other",
    NA = "No Answer"
}