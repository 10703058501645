import { Container } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { CircularProgress } from '@mui/material';
import { useCountdown } from 'react-time-sync';
import { epochSecondsToEpoch } from './timer_utils';
import { TimerProps } from './types';
import TimerContext from '../../../contexts/BehaviorTimerContext';

export const CircularProgressTimer = ({ time_owed = 0, end_time = 0 }: TimerProps) => {
    const { active, setActive} = useContext(TimerContext)
    const [ progress, setProgress ] = useState<number>(0)
    const secondsLeft = useCountdown({ until: epochSecondsToEpoch(end_time) })

    useEffect(() => {
        if (secondsLeft === 0) {
            setActive(false)
        }

        let timeOwedInSeconds = time_owed * 60
        let secondsCompleted = timeOwedInSeconds - secondsLeft

        setProgress(
            Math.round((secondsCompleted / timeOwedInSeconds) * 100)
        )
        
    }, [secondsLeft])

      
    return (
        <CircularProgress
            variant='determinate'
            value={progress} 
            size='4rem'
            thickness={10}
            color={ active ? 'primary' : 'success'}
        />
    )
}