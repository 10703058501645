import React, { useState, useEffect } from 'react'
import { FormLabel, Button, Stack, TextField } from '@mui/material'
import { padTime } from 'functions/time';

interface TimeInputProps {
    label? : string
    value? : number
    pattern? : string
    onTimeChange : Function
}

export const TimeInput = ({ label = "Time", onTimeChange, value = 0 } : TimeInputProps) => {
    const [timeValue, setTimeValue] = useState<number>(value)
    const [timeDisplay, setTimeDisplay] = useState<string>(padTime(timeValue))

    useEffect(() => {
        setTimeDisplay(
            padTime(timeValue)
        )
        onTimeChange(timeValue)
    }, [timeValue, setTimeDisplay])

    return (
        <Stack direction='column' sx={{width: '100%'}}>
            <FormLabel sx={{ textAlign: 'center' }}>{label}</FormLabel>
            <Button onClick={(e) => { 
                e.preventDefault()
                e.stopPropagation()
                setTimeValue(timeValue + 1 )}}
            >
                +
            </Button>
            <TextField 
                id="timeValue"
                value={timeDisplay}
                variant='standard'
                inputProps={{
                    style: { textAlign: 'center' }
                }}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
                onChange={(e) => { 
                    setTimeValue(parseInt(e.target.value))
                }}
            />
            <Button onClick={(e) => { 
                    e.preventDefault()
                    e.stopPropagation()
                    if (timeValue > 0) { setTimeValue(timeValue - 1 ) } 
                }}
            >
                -
            </Button>
        </Stack>
    )
}