import React, { useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { Filter, FilterOperator } from './types';
import { addFilter, removeFilter } from './utils';

export interface AutocompleteProps {
    label: string
    options : Object[]
    optionLabel? : string
    filters : Filter[]
    filterProperty : string
    setFilters : Function
}

export const AutocompleteFilter = ({ options, optionLabel, label, filters, setFilters, filterProperty } : AutocompleteProps) => {
    const [value, setValue] = useState<any>(null)

    const handleChange = (event: any, newValue: any | null) => {
        let updatedFilters = filters.slice()

        // clear filters when input is cleared
        if (value !== null && newValue === null) {
            updatedFilters = removeFilter(
                updatedFilters,
                {
                    property: filterProperty,
                    value: value instanceof Object ? value.id : value,
                    operator: FilterOperator.EQ
                }
            )
        }

        if (newValue) {
            updatedFilters = addFilter(
                updatedFilters,
                {
                    property: filterProperty,
                    value: newValue instanceof Object ? newValue.id : newValue,
                    operator: FilterOperator.EQ
                }
            )
        }

        setFilters(updatedFilters)
        setValue(newValue)
    }

    return (
        <Autocomplete
          disablePortal={true}
          forcePopupIcon={false}
          value={value}
          id={`${optionLabel}-ac`}
          options={options}
          // @ts-ignore
          getOptionLabel={(option : Object | string) => optionLabel ? option[optionLabel] : option}
          sx={{ width: '15%' }}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      );
}