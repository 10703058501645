import { Button, styled } from "@mui/material";

export const AddTimerButton = styled(Button)(
    ({ theme }) => ({
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.contrastText,
        }
    })
);