
import React, {useState} from 'react';
import { IconButton, Dialog } from '@mui/material';
import { Edit } from '@mui/icons-material'
import { EditBasicBehaviorCodeDialog } from './EditBasicBehaviorCodeDialog';
import { BehaviorCodeTimer } from 'dtos/timers';

interface EditBasicBehaviorCodeButtonProps {
    behaviorCodeTimer : BehaviorCodeTimer
}
export const EditBasicBehaviorCodeButton = ({ behaviorCodeTimer } : EditBasicBehaviorCodeButtonProps) => {
    const [open, setOpen] = useState<boolean>(false)

    return (
        <>
            <IconButton
                sx={{ position: 'absolute', top: '0', right: '0'}}
                onClick={(e) => { 
                    e.preventDefault()
                    e.stopPropagation()
                    setOpen(true) 
                }}
            >
                <Edit />
            </IconButton>

            <Dialog 
                open={open} 
                onClose={(e) => { 
                    // @ts-ignore
                    e.preventDefault()
                    // @ts-ignore
                    e.stopPropagation()
                    setOpen(false) 
                }}
            >
                <EditBasicBehaviorCodeDialog setOpen={setOpen} behaviorCodeTimer={behaviorCodeTimer} />
            </Dialog>
        </>
        
    )
}