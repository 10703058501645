import React from 'react';
import { Typography, Box, BoxProps } from '@mui/material';

export interface DashboardDataBoxProps extends BoxProps {
    data: string | number,
    description: string
    sx?: Record<string, any>
}

export const DashboardDataBox = ({ data, description, sx = {} } : DashboardDataBoxProps) => {
    return (
        <Box 
            display='flex'
            flexDirection='column'
            alignItems='center'
            padding={2}
            boxShadow={2}
            borderRadius={2}
            sx={sx}
        >
            <Typography variant="h3" color='primary'>{data}</Typography>
            <Typography sx={{ paddingTop: 1}}>{description}</Typography>
        </Box>
    )
}