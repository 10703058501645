import { Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useCountdown } from 'react-time-sync';
import { epochSecondsToEpoch, secondsToRemainingTime, padTime } from './timer_utils';
import { RemainingTime, TimerProps } from './types';
import TimerContext from 'contexts/BehaviorTimerContext';


export const CountdownTimer = ({ end_time = 0 }: TimerProps) => {
    const { active, setActive} = useContext(TimerContext)
    const secondsLeft = useCountdown({ until: epochSecondsToEpoch(end_time) })
    const [ timeLeft, setTimeLeft ] = useState<RemainingTime>(secondsToRemainingTime(secondsLeft));

    useEffect(() => {
        if (secondsLeft === 0) {
            setActive(false)
        }

        setTimeLeft(
            secondsToRemainingTime(secondsLeft)
        )
    }, [secondsLeft])

      
    return (
        <Typography 
            variant='h3'
            color={ active ? '#2492D9' : '#4CAF50' }
        >
                {padTime(timeLeft.minutes)}:{padTime(timeLeft.seconds)}
        </Typography> 
    )
}