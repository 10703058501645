import React, { useState, MouseEvent } from 'react'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import info from '../../../build.json';
import { BuildInfo } from '../../../types/dtos';
import { useAppDispatch } from 'app/hooks';
import { endSession } from 'features/auth/authSlice';

const { version, build } = info as BuildInfo

const settings = [ 'Logout' ];

export const Profile = () => {
    const dispatch = useAppDispatch()
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        dispatch(endSession())
    }

    return (
        <>
            <IconButton
                size="large"
                sx={{ p: 0 }}
                onClick={handleOpenUserMenu}
            >
                <Avatar 
                    sx={{ height: 56, width: 56 }}>
                </Avatar>
            </IconButton>

            <Box sx={{ flexGrow: 0 }}>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >       
                    <MenuItem key="1" onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">{version}-{build}</Typography>
                    </MenuItem>
                    
                    {settings.map((setting) => (
                        <MenuItem key={setting} onClick={handleLogout}>
                            <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </>
    )
}