import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { VolumeOff, VolumeUp } from '@mui/icons-material';
import { useTime } from 'react-time-sync';
import brightChimes from "assets/sounds/bright_chimes.mp3"

export const TimedAudioPlayer = () => {
    const currentTime = useTime()
    const [intervalSeconds, setIntervalSeconds] = useState<number>(900) 
    const audio = useRef(null)
    const [paused, setPaused] = useState<boolean>(false)
    const [shouldPlay, setShouldPlay] = useState<boolean>(false)
    const [url, setUrl] = useState<string>(brightChimes)
    
    const togglePause = () => {
        setPaused(!paused)
    }

    useEffect(() => {
        let mod = currentTime % intervalSeconds

        if (mod === 0) {
            setShouldPlay(true)
        } else {
            setShouldPlay(false)
        }
        
    }, [currentTime, intervalSeconds])

    useEffect(() => {
        if (!paused && shouldPlay) {
            // @ts-ignore
            audio.current.play()
        }
    }, [shouldPlay, paused])

    return (
        <>
            <Tooltip placement='left' title={`Notification every ${Math.floor(intervalSeconds / 60)} min(s) ${paused ? 'off' : 'on'}`} enterDelay={500} arrow >
                <IconButton onClick={togglePause}>
                    { paused ? <VolumeOff /> : <VolumeUp /> }
                </IconButton>
            </Tooltip>
            <audio ref={audio} src={url}/>
            </>       
    )
}