import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Stack, Typography } from '@mui/material';
import { Class } from '../../../types/dtos';

export interface ClassListItemProps {
    clazz: Class
}

export const ClassListItem = ({ clazz } : ClassListItemProps) => {
    const navigate = useNavigate()
    return (
        <Container 
            onClick={(e) => {
                e.preventDefault()
                navigate(`/classes/${clazz.id}`)
            }}       
            sx={{ width: '50%', margin: 2.5, textDecoration: 'none', cursor: 'pointer'}}>
            <Stack direction="column">
                <Typography variant="h6">{clazz.name}</Typography>
                <Typography>Period {clazz.period}</Typography>
                <Typography>{clazz.location}</Typography>
                <Typography>{clazz.students.length} student(s)</Typography>
            </Stack>
        </Container>
    )
}