import React, { useEffect, useState } from 'react'
import { Container, DialogTitle, DialogContent, TextField, Stack } from '@mui/material';
import { useAppDispatch } from 'app/hooks';
import { DialogActions } from 'components/buttons/DialogActions';
import { BehaviorCodeTimer } from 'dtos/timers';
import { deleteTimer } from 'features/timers/timersSlice';

interface RemoveBasicBehaviorCodeDialogProps {
    behaviorCodeTimer: BehaviorCodeTimer
    setOpen: Function
}

export const RemoveBasicBehaviorCodeDialog = ({ setOpen, behaviorCodeTimer } : RemoveBasicBehaviorCodeDialogProps) => {
    const dispatch = useAppDispatch()
    const [ ready, setReady ] = useState<boolean>(false)

    useEffect(() => {
        if (ready) {
            dispatch(deleteTimer(behaviorCodeTimer))
            setOpen(false)
        }
    }, [ready, behaviorCodeTimer, dispatch, setOpen])


    return (
        <Container> 
            <DialogTitle sx={{ margin: "auto", textAlign: 'center' }}>Remove From Quick Room</DialogTitle>

            <DialogContent>
                <Stack direction="column" spacing={5}>
                    <TextField 
                        id="student"
                        label="Student Name"
                        defaultValue={behaviorCodeTimer.timer_label}
                        variant="standard"
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                            readOnly: true
                        }}
                    />
                </Stack>
            </DialogContent>

            <DialogActions
                onCancel={(e) => { 
                    e.preventDefault()
                    setOpen(false) 
                }}
                onSubmit={(e) => { setReady(true) }}
                cancelText="Cancel"
                submitText="Remove Timer"
            />
        </Container>
    )
}