import React, { useState } from 'react'
import { Dialog } from '@mui/material'
import { AddBasicBehaviorCodeDialog } from './AddBasicBehaviorCodeDialog';
import { AddTimerButton } from 'components/AddTimerButton';

export const AddBasicBehaviorCodeButton = () => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <AddTimerButton sx={{width: '100%'}} onClick={(e) => { setOpen(true) }} >
                Add Timer
            </AddTimerButton>

            <Dialog open={open} onClose={(e) => { setOpen(false) }}>
                <AddBasicBehaviorCodeDialog setOpen={setOpen} />
            </Dialog>
        </>
    )
}