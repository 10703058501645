import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography, Menu, MenuItem, IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material'
import { CountdownTimer } from './CountdownTimer';
import { CircularProgressTimer } from './CircularProgressTimer';
import { LinearProgressTimer } from './LinearProgressTimer';
import { determineEndEpochSeconds } from './timer_utils';
import { ActiveReferral } from '../referralsSlice';
import styles from '../Referral.module.css'
import { BehaviorTimerProvider } from '../../../contexts/BehaviorTimerContext';


export interface ReferralTimerSelectorProps {
    referral: ActiveReferral
}

const timerOptions = [
    { type: 0, name: 'Numeric Countdown' },
    { type: 1, name: 'Donut Fill' },
    { type: 2, name: 'Linear Fill' }
]

const determineTimerComponent = function(timerType: number, referral : ActiveReferral) : JSX.Element {
    switch(timerType) {
        case 1:
            return (<CircularProgressTimer end_time={determineEndEpochSeconds(referral.intake_time || 0, referral.time_owed || 0)} time_owed={referral?.time_owed} />)
        case 2: 
            return (<LinearProgressTimer end_time={determineEndEpochSeconds(referral.intake_time || 0, referral.time_owed || 0)} time_owed={referral?.time_owed} />)
        default:
            return (<CountdownTimer end_time={determineEndEpochSeconds(referral.intake_time || 0, referral.time_owed || 0)} />)
    }
}

export const ReferralTimerSelector = ({ referral } : ReferralTimerSelectorProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false)
    const [timerType, setTimerType] = useState<number>(referral.timer || 0)
    const [active, setActive] = useState<boolean>(true)
    const [ timerComponent, setTimerComponent ] = useState<JSX.Element>(determineTimerComponent(timerType, referral))
    
    useEffect(() => {
        setTimerComponent(
            determineTimerComponent(timerType, referral)
        )
    }, [timerType, setTimerType, referral])
    
    return (
        <Box 
            className={ !active ? styles['pulse-green'] : ''}
            borderRadius={2}
        >        
            <Stack 
                spacing={2}
                direction='column'
                alignItems='center'
            >   
                <Box
                    display='flex'
                    height='5rem'
                    width='100%'
                    justifyContent='center'
                    alignItems='center'
                >
                    <BehaviorTimerProvider value={{ active, setActive }}>
                        { timerComponent }
                    </BehaviorTimerProvider>
                </Box>
                
                <Stack
                    direction='row'
                    alignItems='center'
                >
                    <Typography flexGrow={1} variant='h5'>{referral?.student?.full_name}</Typography>
                    <IconButton
                        onClick={(e) => { 
                            e.preventDefault()
                            e.stopPropagation()
                            setAnchorEl(e.currentTarget)
                            setOpen(true) 
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                        'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => {
                                setAnchorEl(null)
                                setOpen(false)
                            }}
                        PaperProps={{
                        style: {
                            maxHeight: 48 * 4.5,
                            width: '20ch',
                            zIndex: 9999
                        },
                        }}
                    >
                        {
                            timerOptions.map((option) => (
                                <MenuItem 
                                    key={Math.random().toString().slice(2)} 
                                    selected={option.type === timerType} 
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setAnchorEl(null)
                                        setOpen(false)
                                        setTimerType(option.type)
                                    }}
                                >
                                    {option.name}
                                </MenuItem>
                                )
                            )
                        }
                    </Menu>
                </Stack>
                
            </Stack>
        </Box>
    )
}