import { Teacher } from "../types/dtos"

export const referrers = [
    {
        id: "2",
        full_name: "Dr. Paul Gavoni",
        first_name: "Paul",
        last_name: "Gavoni"
    },
    {
        id: '3',
        full_name: "Gaul Pavoni Ed.D",
        first_name: "Gaul",
        last_name: "Pavoni"
    }
] as Teacher[]