import React, { useState, useMemo } from 'react'
import { Container, Stack, Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/lab'
import { useSelector } from 'react-redux';
import { selectDischargedReferrals } from '../referral/referralsSlice';
import { asArray, isSameDay, filterByDate, getStartOfDaysPrior, getEndOfToday, groupByProperty, getAverageTimeOwed, getMostReferredBehavior, maxByProperty, getStartOfToday, filterReferrals} from '../filters/utils';
import { DashboardDataBox } from '../../components/DashboardDataBox';
import { DateTimeFilter } from '../filters/DateTime';
import { AutocompleteFilter } from "../filters/Autocomplete"
import DateAdapter from '@mui/lab/AdapterMoment'
import { MultiDayLineChart } from '../charts/MultiDayLineChart';
import { SingleDayLineChart } from '../charts/SingleDayLineChart';
import { Filter, ReferralCodeFilter } from '../filters/types';
import { PropertyGroupedBarChart } from '../charts/PropertyGroupedBarChart';

import { students } from '../../demo/students';
import { teachers } from '../../demo/teachers';
import { referrers } from '../../demo/referrers';
import { ReferralCode } from '../../types/dtos';

// key is dashboardFitlers
export interface LocalDashboardFilterState {
    before : Date
    after : Date
    behaviorCode : ReferralCodeFilter
    teacherId : string
    classId : string
    studentId : string
}

const initialFilters = {
    before: getStartOfDaysPrior(7),
    after: getEndOfToday(),
    behaviorCode: ReferralCodeFilter.All,
    teacherId: '',
    classId: '',
    studentId: ''

} as LocalDashboardFilterState

export const FilterableData = () => {
    const dischargedReferrals = useSelector(selectDischargedReferrals)
    const [filters, setFilters] = useState<Filter[]>([])
    const [after, setAfter] = useState<Date>(getStartOfDaysPrior(7))
    const [before, setBefore] = useState<Date>(getEndOfToday())
    

    const referralsDateFiltered = useMemo(
        () => filterByDate(dischargedReferrals, after, before),
        [dischargedReferrals, after, before]
    )

    const filteredReferrals = useMemo(
        () => filterReferrals(filters, referralsDateFiltered),
        [filters, referralsDateFiltered]
    )

    const referralsGroupedByDay = useMemo(
        () => groupByProperty("referral_date_string", filteredReferrals),
        [filteredReferrals]
    )

    const referralsGroupedByTime = useMemo(
        () => groupByProperty("referral_time", filteredReferrals),
        [filteredReferrals]
    )

    const referralsGroupedByBehaviorCode = useMemo(
        () => groupByProperty("code", filteredReferrals),
        [filteredReferrals]
    )
    
    const referralsGroupedByTeacher = useMemo(
        () => groupByProperty("teacher.full_name", filteredReferrals),
        [filteredReferrals]
    )

    const referralsGroupedByReferrer = useMemo(
        () => groupByProperty("referrer.full_name", filteredReferrals),
        [filteredReferrals]
    )

    const referralsGroupedByWorkCompleted = useMemo(
        () => groupByProperty("work_completed", filteredReferrals),
        [filteredReferrals]
    )

    const referralsGroupedByEscortRequired = useMemo(
        () => groupByProperty("escort_required", filteredReferrals),
        [filteredReferrals]
    )

    const referralsGroupedByLocation = useMemo(
        () => groupByProperty("class.location", filteredReferrals),
        [filteredReferrals]
    )

    const referralsGroupedByGrade = useMemo(
        () => groupByProperty("student.grade", filteredReferrals),
        [filteredReferrals]
    )

    const referralsGroupedByStudentGender = useMemo(
        () => groupByProperty("student.gender", filteredReferrals),
        [filteredReferrals]
    )

    const referralsGroupedByStudentRace = useMemo(
        () => groupByProperty("student.race", filteredReferrals),
        [filteredReferrals]
    )

    const referralsGroupedByStudentEthnicity = useMemo(
        () => groupByProperty("student.ethnicity", filteredReferrals),
        [filteredReferrals]
    )

    return (
        <Container>
            <Stack
                direction='column'
                spacing={5}
            >
                <Stack 
                    direction='row' 
                    spacing={2}
                    pt={2}
                    justifyContent='space-around'
                >
                    <DashboardDataBox 
                        data={Object.keys(filteredReferrals).length.toString()}
                        description='Total Behavior Codes'
                    />

                    <DashboardDataBox 
                        data={getAverageTimeOwed(asArray(filteredReferrals))}
                        description='Average Time Owed'
                    />

                    <DashboardDataBox 
                        data={getMostReferredBehavior(filteredReferrals).type}
                        description='Most Frequent Type'
                    />

                    <DashboardDataBox 
                        data={maxByProperty("class.period", filteredReferrals).value}
                        description='Most Frequent Period'
                    />
                </Stack>

                <Typography variant="h4">Filters</Typography>

                <Stack
                    direction='row'
                    justifyContent='space-between'
                    spacing={1}
                >   
                    <AutocompleteFilter 
                        options={referrers}
                        optionLabel="full_name"
                        label="Referrers"
                        filters={filters}
                        setFilters={setFilters}
                        filterProperty="referrer.id"
                    />

                    <AutocompleteFilter 
                        options={teachers}
                        optionLabel="full_name"
                        label="Teachers"
                        filters={filters}
                        setFilters={setFilters}
                        filterProperty="teacher.id"
                    />

                    <AutocompleteFilter 
                        options={students}
                        optionLabel="full_name"
                        label="Students"
                        filters={filters}
                        setFilters={setFilters}
                        filterProperty="student.id"
                    />    

                    <AutocompleteFilter
                        options={Object.values(ReferralCode)}
                        label="Code Type"
                        filterProperty='code'
                        filters={filters}
                        setFilters={setFilters}
                    />
                </Stack>

                <Stack
                    direction='row'
                    justifyContent='space-between'
                    spacing={1}
                    pt={2}
                >
                    <LocalizationProvider dateAdapter={DateAdapter}>
                         <DateTimeFilter 
                            label="After"
                            initialDate={after}
                            cb={setAfter}
                        />

                        <DateTimeFilter 
                            label="Before"
                            initialDate={before}
                            cb={setBefore}
                        />
                    </LocalizationProvider>
                </Stack>

                <Box
                    width='100%'
                    height='30rem'
                >   
                    {
                        isSameDay(before, after)
                        ? ( <SingleDayLineChart title="Single Day" data={asArray(filteredReferrals)} lines={[ { dataKey: "time_owed" } ]} />)
                        : ( <MultiDayLineChart title="Multi Day" data={referralsGroupedByDay} lines={[ { dataKey: "count" } ]} />)
                    }
                </Box>
            </Stack>

            <Stack
                direction='row'
                justifyContent='space-around'
                width='100%'
                spacing={5}
            >
                <Stack
                    direction='column'
                    width='50%'
                    spacing={5}
                >
                    <PropertyGroupedBarChart 
                        title='Codes by Work Completion'
                        data={referralsGroupedByWorkCompleted}
                        bars={[{ dataKey: "count" }]} 
                        xAxisProps={{
                            angle: 30, 
                            tickFormatter: (wc : string) => {
                                return wc === 'true' ? 'Completed' : 'Not Completed'
                            } 
                        }}  
                    />    

                    <PropertyGroupedBarChart
                        title='Codes by Type'
                        data={referralsGroupedByBehaviorCode} 
                        bars={[{ dataKey: "count" }]} 
                    />  

                    <PropertyGroupedBarChart 
                        title='Codes by Grade'
                        data={referralsGroupedByGrade}
                        bars={[{ dataKey: "count" }]}
                        xAxisProps={{ dy: 15, angle: 1 }}
                    />

                    <PropertyGroupedBarChart 
                        title='Codes by Teacher'
                        data={referralsGroupedByTeacher}
                        bars={[{ dataKey: "count" }]}
                        xAxisProps={{ angle: 30 }}
                    />

                    <PropertyGroupedBarChart 
                        title='Codes by Referrer'
                        data={referralsGroupedByReferrer}
                        bars={[{ dataKey: "count" }]}
                        xAxisProps={{ angle: 30 }}
                    />
                </Stack>

                <Stack
                    direction='column'
                    width='50%'
                    spacing={5}
                >
                    <PropertyGroupedBarChart 
                        title='Codes by Escort Required'
                        data={referralsGroupedByEscortRequired}
                        bars={[{ dataKey: "count" }]}
                        xAxisProps={{ 
                            angle: 30,
                            tickFormatter: (er : string) => {
                                return er === 'true' ? 'Required' : 'Not Required'
                            } 
                        }}   
                    />

                    <PropertyGroupedBarChart
                        title='Codes by Student Gender'
                        data={referralsGroupedByStudentGender}
                        bars={[{ dataKey: 'count' }]}
                    />

                    <PropertyGroupedBarChart
                        title='Codes by Student Ethnicity'
                        data={referralsGroupedByStudentEthnicity}
                        bars={[{ dataKey: 'count' }]}
                        xAxisProps={{ dy: 35, angle: 25}}
                    />

                    <PropertyGroupedBarChart
                        title='Codes by Student Race'
                        data={referralsGroupedByStudentRace}
                        bars={[{ dataKey: 'count' }]}
                        xAxisProps={{ 
                            tickFormatter: (race : string) => {
                                switch(race) {
                                    case 'American Indian or Alaska Native':
                                        return 'AIoAN'
                                    case 'Black or African American':
                                        return 'BoAA'
                                    case 'Native Hawaiian or Other Pacific Islander':
                                        return 'NHoOPI'
                                    case 'No Answer':
                                        return 'N/A'
                                    default:
                                        return race
                                }
                            } 
                        }}
                    />

                    <PropertyGroupedBarChart 
                        title='Codes by Location'
                        data={referralsGroupedByLocation}
                        bars={[{ dataKey: "count" }]}
                    />

                </Stack>

            </Stack>
        </Container>
    )
}