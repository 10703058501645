import f from 'faker';
import DataGenerator from './types'

export interface ClassGeneratorOpts {
    district_id?: string
    school_id?: string
    name?: string
    period?: number
    location?: string
}

export default class ClassGenerator implements DataGenerator {
    public district_id : string
    public school_id : string
    public name : string
    public period : number
    public location : string

    constructor(opts?: ClassGeneratorOpts) {
        this.district_id = opts?.district_id || Math.random().toString().slice(2,7)
        this.school_id = opts?.school_id || Math.random().toString().slice(2,7)
        this.name = opts?.name || `${f.commerce.productAdjective()} ${f.company.catchPhraseNoun()} ${f.datatype.number({ min: 1, max: 5})}0${f.datatype.number({ min: 0, max: 9})}`
        this.period = opts?.period || f.datatype.number({ min: 1, max: 9 })
        this.location = opts?.location || `Room ${f.datatype.number({min:1, max:4})}0${f.datatype.number({min: 0, max: 9})}`
    }

    toJSON() {
        return {
            district_id: this.district_id,
            school_id: this.school_id,
            name: this.name,
            period: this.period,
            location: this.location
        }
    }
}