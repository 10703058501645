import React, { useState } from 'react';
import { Grid, Box, Dialog, Divider } from '@mui/material';
import { Referral } from '../../../types/dtos';
import { DischargeReferral } from '../dialogs/DischargeReferral';
import { ReferralTimerSelector } from '../timers/ReferralTimerSelector';
import { ActiveReferral } from '../referralsSlice';

export interface ActiveReferralGridItemProps {
    referral: ActiveReferral
}

export const ActiveReferralGridItem = ({ referral } : ActiveReferralGridItemProps) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <Grid item sm={1} key={referral.id}>
                <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={(e) => { 
                        e.preventDefault()
                        setOpen(true)
                    }}
                >
                    <ReferralTimerSelector referral={referral} />
                </Box>
            </Grid>

            <Dialog open={open} onClose={(e) => { setOpen(false) }}>
                <DischargeReferral referral={referral} setOpen={setOpen} />
            </Dialog>
        </>
    )
}