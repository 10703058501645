import { Box, Container, Typography } from '@mui/material'
import { CustomTooltipProps } from './types';


export const SingleDayLineChartTooltip = ({active, payload, label} : CustomTooltipProps) => {
  if (active) {
    return (
      <Box
        bgcolor='#fff'
        border={1}
        borderColor='#ccc'
        boxShadow={1}
      >
        <Container>
          <Typography>Student: {payload?.[0].payload.student.full_name}</Typography>
          <Typography>Type: {payload?.[0].payload.code}</Typography>
          <Typography>Period: {payload?.[0].payload.class.period}</Typography>
          <Typography>Time: {payload?.[0].payload.referral_time_string}</Typography>
          <Typography>Time Owed: {`${payload?.[0].payload.time_owed}`}</Typography>
          <Typography>Teacher: {payload?.[0].payload.teacher.full_name}</Typography>
          <Typography>Referrer: {payload?.[0].payload.referrer.full_name}</Typography>
        </Container>
      </Box>
    );
  }

  return null;
};
