import { Container } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import { useCountdown } from 'react-time-sync';
import { epochSecondsToEpoch } from './timer_utils';
import { TimerProps } from './types';
import TimerContext from '../../../contexts/BehaviorTimerContext';


export const LinearProgressTimer = ({ time_owed = 0, end_time = 0 }: TimerProps) => {
    const { active, setActive} = useContext(TimerContext)
    const [ progress, setProgress ] = useState<number>(0)
    const secondsLeft = useCountdown({ until: epochSecondsToEpoch(end_time) })

    useEffect(() => {
        if (secondsLeft === 0) {
            setActive(false)
        }

        let timeOwedInSeconds = time_owed * 60
        let secondsCompleted = timeOwedInSeconds - secondsLeft

        setProgress(
            Math.round((secondsCompleted / timeOwedInSeconds) * 100)
        )
        
    }, [secondsLeft, setActive, time_owed])

      
    return (
        <Container>
            <LinearProgress
                sx={{ height: '1rem', borderRadius: 20 }}
                variant='determinate'
                value={progress} 
                color={ active ? 'primary' : 'success' }
            />
        </Container>        
    )
}