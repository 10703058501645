import { Referral } from '../../types/dtos';

export interface ReferralGroup {
    property: string
    count: number
    referrals: Referral[]
}

export interface Filter {
    property: string
    operator: FilterOperator
    value: any
}

export enum FilterOperator {
    EQ = "eq",
    GT = "gt",
    LT = "lt"
}

export enum ReferralCodeFilter {
    All = "All",
    Aggression = "AGGR",
    PropertyDestruction = "PDST",
    NonCompliance = "NCOM",
    DisruptiveBehavior = "DISR",
    Elopement = "ELOP",
    Language = "LANG"
}