import React from 'react'
import { Container, Stack, Divider } from '@mui/material';
import { classes } from '../../../demo/classes'
import { Class } from '../../../types/dtos';
import { ClassListItem } from './ClassListItem';
export const ClassesList = () => {
    return (
        <Container 
            sx={{ margin: '0px 5px 5px', bgcolor: '#ffffff' }}
        >
            <Stack
                divider={<Divider orientation="horizontal" flexItem />}
            >
                {classes.map(
                    (c : Class) => <ClassListItem key={c.id} clazz={c} />
                )}
            </Stack>
        </Container>
    )
}