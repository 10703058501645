import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import basicBehaviorCodeSlice from 'features/basic/behavior_codes/basicBehaviorCodeSlice';
import referralsReducer from '../features/referral/referralsSlice';
import timersReducer from 'features/timers/timersSlice';
import sessionReducer from 'features/auth/authSlice';
import { loadState, saveState } from 'localStorage';

export const store = configureStore({
  reducer: {
    referrals: referralsReducer,
    basicBehaviorCodes: basicBehaviorCodeSlice,
    timers: timersReducer,
    session: sessionReducer
  },
  preloadedState: loadState()
});

store.subscribe(() => {
  saveState({
    timers: store.getState().timers,
    session: store.getState().session
  })
})


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
