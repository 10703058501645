import { RootState } from "app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BasicBehaviorCode } from 'dtos/basic'

interface BasicBehaviorCodeState {
    active: {}
}

const initialState: BasicBehaviorCodeState = {
    active: {}
}

export const basicBehaviorCodeSlice = createSlice({
    name: 'basic_behavior_codes',
    initialState,
    reducers: {
        addToActive: (state, action: PayloadAction<BasicBehaviorCode>) => {
            if (!action.payload.id) return
            // @ts-ignore
            state.active[action.payload.id] = action.payload
        },
        updateActive: (state, action:  PayloadAction<BasicBehaviorCode>) => {
            // @ts-ignore
            state.active[action.payload.id] = action.payload
        },
        removeFromActive: (state, action: PayloadAction<BasicBehaviorCode>) => {
            if (!action.payload.id) return
            // @ts-ignore
            delete state.active[action.payload.id]
        }
    }
})

export const { addToActive, updateActive, removeFromActive } = basicBehaviorCodeSlice.actions
export const selectActiveBasicBehaviorCodes = (state: RootState) => state.basicBehaviorCodes.active
export default basicBehaviorCodeSlice.reducer