import React, { MouseEventHandler } from 'react';
import { DialogActions as MDialogActions, Stack } from '@mui/material';
import { CancelButton } from 'components/CancelButton';
import { SubmitButton } from 'components/SubmitButton';

interface DialogActionsProps {
    onCancel: MouseEventHandler<HTMLButtonElement>
    onSubmit: MouseEventHandler<HTMLButtonElement>
    cancelText?: string
    submitText?: string
}
export const DialogActions = ({ onCancel, onSubmit, cancelText, submitText }: DialogActionsProps) => {
    return (
        <MDialogActions>
            <Stack
                direction="row"
                sx={{ justifyContent: 'space-between', width: '100%' }}
            >
                <CancelButton onClick={onCancel}>
                    { cancelText || "Cancel" }
                </CancelButton>

                <SubmitButton onClick={onSubmit}>
                    { submitText || "Submit" }
                </SubmitButton>
            </Stack>
        </MDialogActions>
    )
}