import React from 'react'
import { Container } from '@mui/material';
import ClassDetail from '../features/class/detail/ClassDetail'

export const Class = () => {
    return (
        <Container
            sx={{ 
                minHeight: '100vh',
                bgcolor: '#ffffff',
                boxShadow: 5,
            }}
        >
            <ClassDetail />
        </Container>
    )
}