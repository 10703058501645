import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { wallClockTimeFromEpochSeconds } from 'functions/time';
import { useAppSelector } from 'app/hooks';
import { useTime } from 'react-time-sync';
import { AddBasicBehaviorCodeButton } from './AddBasicBehaviorCodeButton';
import { selectTimers } from 'features/timers/timersSlice';
import { TimedAudioPlayer } from 'components/audio/TimedAudioPlayer';

export const BasicBehaviorCodeData = () => {
    const currentTime = useTime()
    const timerState = useAppSelector(selectTimers)

    return (
        <Stack direction="row" spacing={5} justifyContent='space-between' alignItems='center' sx={{ padding: '10px' }}>
            <Stack alignItems='center' spacing={1}>
                <Typography>{`${Object.keys(timerState).length} timer(s)`}</Typography>
                <Box width='8rem'>
                    <AddBasicBehaviorCodeButton />
                </Box>
            </Stack>

            <Stack direction='row' alignItems='center' spacing={1}>
                <TimedAudioPlayer />
                <Typography variant='h5'>{wallClockTimeFromEpochSeconds(currentTime)}</Typography>
            </Stack>
        </Stack>
    )
}