import React from 'react';
import { Toolbar, Stack, Divider } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { removeFromPending, addToActive, selectPendingReferrals } from '../../referral/referralsSlice';
import { Referral } from '../../../types/dtos';
import { PendingReferralQueueItem } from './PendingReferralQueueItem';

export const PendingReferralQueue = () => {
    const pendingReferrals = useAppSelector(selectPendingReferrals);

    return (
        <Toolbar sx={{ minWidth: '100vw', borderTop: 1, borderColor: '#2492D9', position: 'fixed', bottom: 0}}>
            <Stack 
                direction="row" 
                divider={<Divider orientation="vertical" flexItem />}
                spacing={5} 
                sx={{ marginBottom: 5, marginTop: 5, width: 'max-content' }}>
                {
                    Object.entries(pendingReferrals).map(
                        (value: [string, Referral], index: number) => { 
                            return <PendingReferralQueueItem key={value[1].id} referral={value[1]} />
                        }
                    )
                }
            </Stack>
        </Toolbar>
    )
}