import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from 'pages/Layout';
import { BasicQuickRoom } from 'pages/BasicQuickRoom';

export function BasicRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/dashboard" element={<Navigate to="/quick-room" />} />
                    <Route path="/quick-room" element={<BasicQuickRoom />} />
                </Route>
                <Route path="*" element={<Navigate to="/quick-room" />} />
            </Routes>
        </BrowserRouter>
    )
}
