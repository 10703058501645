import React, { useEffect, useState, useContext } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useCountdown } from 'react-time-sync';
import { TimerProps } from './types';
import { epochSecondsToEpoch } from 'functions/time';
import TimerContext from 'contexts/BehaviorTimerContext';

export const DonutDrain = ({ behaviorCodeTimer } : TimerProps) => {
    const { active, setActive } = useContext(TimerContext)
    const [ progress, setProgress ] = useState<number>(0)
    const secondsLeft = useCountdown({ until: epochSecondsToEpoch(behaviorCodeTimer.timer_end_epoch_seconds) })

    useEffect(() => {
        if (!behaviorCodeTimer.paused) {
            if (secondsLeft === 0) {
                setActive(false)
            }

            let totalSecondsCompleted = behaviorCodeTimer.seconds_owed - secondsLeft

            setProgress(
                Math.round(100 - (totalSecondsCompleted / behaviorCodeTimer.seconds_owed) * 100)
            )
        }
    }, [secondsLeft, setActive, behaviorCodeTimer.paused, behaviorCodeTimer.seconds_owed])

      
    return (
        <Box component='div' sx={{ position: 'relative'}}>
            <CircularProgress
                variant='determinate'
                value={100} 
                size='4rem'
                thickness={10}
                sx={{ color: active ? `${behaviorCodeTimer.color}80` : '#4CAF5080' }}
            />
            <CircularProgress
                variant='determinate'
                value={progress} 
                size='4rem'
                thickness={10}
                sx={{ color: active ? behaviorCodeTimer.color : '#4CAF50', position: 'absolute', left: 0 }}
            />
        </Box>
    )
}