import React from 'react'
import { Container } from '@mui/material';
import { ActiveReferralGrid } from '../features/referral/active/ActiveReferralGrid'
import { PendingReferralQueue } from "../features/referral/pending/PendingReferralQueue"


export const EnterpriseQuickRoom = () => {
    return (
        <Container
            sx={{ 
                minWidth: '100vw',
                minHeight: '100vh',
                bgcolor: '#ffffff',
            }}
            disableGutters
        >
            <ActiveReferralGrid />
            <PendingReferralQueue />
        </Container>
    )
}