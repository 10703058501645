import React, { useEffect, useState } from 'react';
import { DialogContent, DialogTitle, DialogActions, Stack, Switch, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { removeFromPending, addToActive } from '../referralsSlice';
import { useAppDispatch } from '../../../app/hooks';
import { Referral } from "../../../types/dtos";
import {CancelButton} from '../../../components/CancelButton'
import {SubmitButton} from '../../../components/SubmitButton'

export interface IntakeReferralProps {
    referral: Referral
    setOpen: Function
}

const timerDisplayText = function(timerCode : number) : string {
    switch (timerCode) {
        case 1:
            return 'Donut Fill'
        case 2:
            return 'Linear Fill'
        default:
            return 'Numeric Countdown'
    }
}
export const IntakeReferral = ({ referral, setOpen } : IntakeReferralProps) => {
    const [ readyToStart, setReadyToStart ] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const [timer, setTimer] = useState<number>(0)

    useEffect(() => {
        if (readyToStart) {
            let now = Math.floor(Date.now() / 1000)

            const startedReferral = {
                ...referral,
                ...{
                    intake_time: now,
                    updated_at: now,
                    timer
                }
            }

            dispatch(addToActive(startedReferral))
            dispatch(removeFromPending(referral))
            setOpen(false)
        }
    }, [readyToStart])

    return (
        <>
            <DialogTitle sx={{ margin: "auto", textAlign: 'center' }}>Intake Student</DialogTitle>

            <DialogContent sx={{ minWidth: '15rem'}}>
                <Stack 
                    direction="column"
                    spacing={5}
                >
                    <TextField 
                        id="student"
                        label="Student"
                        defaultValue={referral?.student?.full_name}
                        variant="standard"
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                            readOnly: true
                        }}
                    />

                    <TextField 
                        id="referralCode"
                        label="Code"
                        value={referral.code}
                        variant='standard'
                        InputProps={{ 
                            inputProps: { style: { textAlign: 'center' } },
                            readOnly: true 
                        }}
                    />
                    
                    <TextField 
                        id="location"
                        label="Location"
                        defaultValue={referral.location}
                        variant="standard"
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                            readOnly: true
                        }}
                    />
                    
                    <TextField 
                        id="timeOwed"
                        label="Time Owed"
                        value={referral.time_owed}
                        variant='standard'
                        type="number"
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]{1,2}',
                            min: 0,
                            style: { textAlign: 'center' }
                        }}
                        InputProps={{ readOnly: true }}
                    />

                    <TextField 
                        id="teacher"
                        label="Teacher"
                        defaultValue={referral?.teacher?.full_name}
                        variant="standard"
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                            readOnly: true
                        }}
                    />

                    <TextField 
                        id="referrer"
                        label="Referrer"
                        defaultValue={referral?.referrer?.full_name}
                        variant="standard"
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                            readOnly: true
                        }}
                    />
                    
                    <FormControl>
                        <InputLabel id="timerlabel">Timer</InputLabel>
                        <Select
                            labelId='timerlabel'
                            id='timer'
                            value={timer}
                            label="Timer"
                            onChange={(e) => { 
                                // e.target.value is an int
                                //@ts-ignore
                                setTimer(e.target.value)
                            }}
                        >
                            <MenuItem value={0}>{timerDisplayText(0)}</MenuItem>
                            <MenuItem value={1}>{timerDisplayText(1)}</MenuItem>
                            <MenuItem value={2}>{timerDisplayText(2)}</MenuItem>
                        </Select>
                    </FormControl>

                    {/* <Switch 
                        checked={referral.escort_required}
                    /> */}

                </Stack>
            </DialogContent>

            <DialogActions >
                <Stack 
                    direction="row"
                    sx={{ justifyContent: 'space-between', width: '100%' }}
                >
                    <CancelButton onClick={() => { setOpen(false) }}>
                        Cancel
                    </CancelButton>

                    <SubmitButton onClick={() => { setReadyToStart(true) } }>
                        Start Timer
                    </SubmitButton>
                </Stack>
            </DialogActions>
        </>
    )
}  