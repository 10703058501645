import { Referral } from "../types/dtos";
import faker, { random } from 'faker' 
import ReferralGenerator from "./data/referral";
import { randomFromArray } from "./utils";
import { referrers } from "./referrers";
import { classes } from "./classes";
import { teachers } from "./teachers"
import { students } from "./students"

const rs = [] as Referral[]

for (let i = 0; i < 5; i++) {
    let clazz = randomFromArray(classes)
    let teacher = randomFromArray(teachers)
    let student = randomFromArray(students)
    let referrer = randomFromArray(referrers)

    rs.push(
        {
            ...new ReferralGenerator(
                {
                    teacher,
                    student,
                    referrer,
                    location: clazz.location,
                    class: clazz
                }
            ).toJSON(),
            ...{
                id: Math.random().toString().slice(2)
            }
        }
        
    )
}

let drs = {} as Record<string, Referral>

for (let i = 0; i < rs.length; i++) {
    drs[rs[i].id || Math.random().toString().slice(2, 7)] = rs[i]
}

export const dreferrals = drs
export const referrals = rs