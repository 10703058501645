import f from 'faker';

export function randomFromArray<T>(arr : Array<T>) : T {
    let randIndex = Math.floor( Math.random() * arr.length)
    return arr[randIndex]
}

export function shuffleArray<T>(array: Array<T>) : Array<T> {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
} 

export function getRandomCreateTime() {
    return Math.floor( f.date.recent(30).getTime() / 1000 ) 
}

export function getRandomStartTime(createTime: number) : number {
    // random time up to 7 minutes
    const randomSeconds = Math.floor(Math.random() * 7 * 60)
    const start = createTime + randomSeconds
    return start
}

export function getRandomDischargeTime(startTime: number, timeOwed: number) : number {
    // random time up to 1.5 minutes
    const random = Math.floor(Math.random() * 90) 
    const end = startTime + (timeOwed * 60)
    return Math.random() > 0.5 
    ? end + random
    : end - random
}

export const randomFromEnum = (e: any) : string => {
    let numValues = Object.values(e).length
    let randomIndex = Math.floor(Math.random() * numValues)
    return Object.values(e)[randomIndex] as unknown as string
}