import React, { useEffect, useState } from 'react';
import { Student, Class, Teacher } from '../../../types/dtos';
import { Box, Avatar, Stack, Typography, Container, Dialog, Grid, Divider } from '@mui/material';
import ClassStudent from './ClassStudent'

import { classes } from "./../../../demo/classes"
import { CreateReferral } from '../../referral/dialogs/CreateReferral'
import { useParams } from 'react-router-dom';

const referrer = {
    id: "2",
    full_name: "Dr. Paul Gavoni",
    first_name: "Paul",
    last_name: "Gavoni"
} as Teacher

function ClassDetail() {
    let {classId} = useParams()
    const [clazz, setClazz] = useState<Class>()
    const [teacher, setTeacher] = useState<Teacher>()
    const [students, setStudents] = useState<Student[]>([])
    const [dialogContent, setDialogContent] = useState(null as any)
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        for (let i = 0; i < classes.length; i++) {
            if (classes[i].id === classId) {
                setClazz(classes[i])
                setTeacher(classes[i].teachers[0])
                setStudents(classes[i].students)
            }
        }
    }, [classId])

    return (
        <>
            {
                clazz && teacher && students && (
                <Container sx={{ minWidth: '100%', margin: '0px 5px 5px', paddingTop: '2rem'}}>
                    <Stack 
                        direction='column'
                        spacing={1}
                        sx={{ marginBottom: 5, width: '100%'}}
                    >
                        <Typography variant="h4">{clazz?.name}</Typography>

                        <Stack 
                            direction='row' 
                            spacing={1}
                            divider={<Divider />}
                            sx={{ width: '50%' }}
                        >
                            <Box
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                                justifyContent='center'
                            >
                                <Avatar src={teacher.image} sx={{ width: 72, height: 72 }}>DW</Avatar>
                                <Typography textAlign='center'>{teacher.full_name}</Typography>
                            </Box>

                            <Container>
                                <Typography variant='h6'>Period {clazz?.period}</Typography>
                                <Typography variant='h6'>{clazz?.location}</Typography>
                            </Container>
                        </Stack>
                        
                        
                    </Stack>
                    <Grid 
                        container
                        columns={5} 
                        spacing={2}
                        wrap="wrap"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-evenly"
                    >
                        { 
                            students.map((s : Student) => (
                                <Grid item sm={1} key={s.id}>
                                    <div onClick={(e) => { 
                                        e.preventDefault()

                                        setDialogContent(
                                            <CreateReferral
                                                student={s}
                                                clazz={clazz}
                                                teacher={teacher}
                                                referrer={referrer}
                                                setOpen={setOpen}
                                            />
                                        )

                                        setOpen(true)
                                    }}>
                                        <ClassStudent student={s} /> 
                                    </div> 
                                </Grid>
                            )) 
                        }   
                    </Grid>
                    
                    <Dialog 
                        open={open} 
                        onClose={(e) => { setOpen(false) }}
                    >
                        { dialogContent }
                    </Dialog>
                </Container>
            )}
        </>
      
    )
}

export default ClassDetail