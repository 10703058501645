import { TimerUnits } from "components/timers/types"

/**
 * @param s epoch seconds
 * @returns epoch milliseconds
 */
export const epochSecondsToEpoch = (s : number) : number => {
    return s * 1000
}

/**
 * 
 * @param intake_time epoch seconds
 * @param time_owed minutes
 * @returns 
 */
export const determineEndEpochSeconds = (intake_time: number, time_owed: number) : number => {
    let timeOwedInSeconds = time_owed * 60
    return intake_time + timeOwedInSeconds
}   

export const dateFromEpochSeconds = (epochSeconds : number) : Date => {
    return new Date(epochSeconds * 1000)
}

export const getSecondsOwed = (timeOwed : number, timeUnits : TimerUnits) : number => {
    switch(timeUnits) {
        case TimerUnits.Minutes:
            return timeOwed * 60
        case TimerUnits.Hours:
            return timeOwed * 60 * 60
        default:
            return timeOwed
    }
}

export const getCurrentEpochSeconds = () : number => {
    return Math.floor( Date.now() / 1000)
}

export const calculateTimeRemaining = (endEpochSeconds: number) : RemainingTime => {
    let nowEpochSeconds = Math.floor(Date.now() /1000)
    
    let diff = endEpochSeconds - nowEpochSeconds

    if ( diff < 0 ) return { hours: 0, minutes: 0, seconds: 0 }

    return { 
        hours: Math.floor((diff / (60 * 60)) % 60),
        minutes: Math.floor((diff / 60) % 60),
        seconds: Math.floor(diff % 60)
    }   
}

export const getWallClockTimeComponenets = (s : number) : RemainingTime => {
    let d = new Date(s * 1000)

    return {
        hours: d.getHours(),
        minutes: d.getMinutes(),
        seconds: d.getSeconds()
    }
}

export const wallClockTimeFromEpochSeconds = (s : number) : string => {
    let d = new Date(s * 1000)

    return `${padTime(d.getHours())}:${padTime(d.getMinutes())}:${padTime(d.getSeconds())}`
}
/**
 * 
 * @param s seconds
 * @returns 
 */
export const secondsToRemainingTime = (s : number) : RemainingTime => {
    if ( s === 0) return { hours:0, minutes: 0, seconds: 0 }
    
    return {
        hours: Math.floor((s / (60 * 60)) % 60),
        minutes: Math.floor((s / 60) % 60),
        seconds: Math.floor(s % 60)
    }
}

export const padTime = (time: number | string) : string => {
    let str = time.toString()

    if (str.length < 2) return `0${str}`

    if (str.length > 2) return str.slice(str.length - 2)

    return str
}

export const getRemainingDisplayTime = (t: RemainingTime) : string => {
    let displayTime = ''

    if (t.hours) {
        displayTime += padTime(t.hours) + ":"
    }

    displayTime += padTime(t.minutes) + ":" + padTime(t.seconds)

    return displayTime
}

export interface RemainingTime {
    hours: number,
    minutes: number,
    seconds: number
}