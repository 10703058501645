import React, { useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { HourglassEmpty } from '@mui/icons-material'
import { TimerType } from 'components/timers/types';
import { useAppDispatch } from 'app/hooks';
import { BehaviorCodeTimer } from 'dtos/timers';
import { putTimer } from 'features/timers/timersSlice';

interface BasicBehaviorCodeTimerSelectorProps {
    behaviorCodeTimer: BehaviorCodeTimer
}

const timerOptions = [
    { type: 0, name: 'Countdown' },
    { type: 1, name: 'Donut Fill' },
    { type: 2, name: 'Linear Fill' },
    { type: 3, name: 'Donut Drain' },
    { type: 4, name: 'Linear Drain'}
]

export const BasicBehaviorCodeTimerSelector = ( { behaviorCodeTimer } : BasicBehaviorCodeTimerSelectorProps) => {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [ open, setOpen] = useState<boolean>(false);
    const [ timerType, setTimerType ] = useState<TimerType>(behaviorCodeTimer.timer_type)


    useEffect(() => {
        if (timerType !== behaviorCodeTimer.timer_type) {
            let newCode = { ...behaviorCodeTimer,  ...{ timer_type: timerType }}
            dispatch(putTimer(newCode))
        }
    }, [timerType, behaviorCodeTimer, dispatch])

    return (
        <>
            <IconButton
                sx={{ position: 'absolute', bottom: '0', left: '0'}}
                onClick={(e) => { 
                    e.preventDefault()
                    e.stopPropagation()
                    setAnchorEl(e.currentTarget)
                    setOpen(true) 
                }}
            >
                <HourglassEmpty />
            </IconButton>

            <Menu
                id="long-menu"
                MenuListProps={{ 'aria-labelledby': 'long-button',}}
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                    // @ts-ignore
                    e.preventDefault()
                    // @ts-ignore
                    e.stopPropagation()
                    setAnchorEl(null)
                    setOpen(false)
                }}
                PaperProps={{
                style: {
                    maxHeight: 48 * 4.5,
                    width: '20ch',
                    zIndex: 9999
                },
                }}
            >
                {
                    timerOptions.map((option) => (
                        <MenuItem 
                            key={option.type} 
                            selected={option.type === timerType} 
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setAnchorEl(null)
                                setOpen(false)
                                setTimerType(option.type)
                            }}
                        >
                            {option.name}
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    )
    
}