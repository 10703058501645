import React, { useState } from 'react';
import { Referral } from '../../../types/dtos';
import { IntakeReferral } from '../../referral/dialogs/IntakeReferral';
import { Box, Stack, Dialog, Typography, Container } from '@mui/material';
import styles from '../Referral.module.css'

export interface PendingReferralQueueItemProps {
    referral: Referral
}

const getDate = (time : number | undefined) : Date => {
    if (!time) {
        return new Date()
    } 
    
    return new Date(time * 1000)
}

const getBorderColor = (escort_required : Boolean | undefined) => {
    return escort_required 
    ? '#F44336' 
    : '#2492D9'
}

export const PendingReferralQueueItem = ({ referral }: PendingReferralQueueItemProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const borderColor = getBorderColor(referral?.escort_required)

    return (
        <>
            <Box 
                className={ referral?.escort_required ? styles.pendingER : styles.pending }
                border={2}
                borderColor={borderColor}
                sx={{ borderRadius: 1, boxShadow: 2, cursor: 'pointer', '&:hover': { boxShadow: 5 }}}
                onClick={(e) => {
                    e.preventDefault()
                    setOpen(true)
                }}
            >   
                <Container sx={{ margin: '5px' }}>
                    <Stack direction="column">
                        <Typography>Student: {referral?.student?.full_name}</Typography>
                        <Typography>Location: {referral?.location}</Typography>
                        <Typography>Code Time: {getDate(referral?.referral_time).toLocaleTimeString()}</Typography>
                        <Typography>Time Owed: {referral?.time_owed}</Typography>
                    </Stack>    
                </Container>       
            </Box>

            <Dialog open={open} onClose={(e) => { setOpen(false) }}>
                <IntakeReferral referral={referral} setOpen={setOpen} />   
            </Dialog>
        </>
        
    )
}