import React from 'react';
import { Stack } from '@mui/material'
import { TimeInput } from './TimeInput';

interface TimeOwedInputProps {
    onHoursChange? : Function
    onMinutesChange? : Function
    onSecondsChange? : Function
    hoursOwed? : number
    minutesOwed? : number
    secondsOwed? : number
}

const defaultHandler = () => {}

export const TimeOwedInput = ({ 
    onHoursChange = defaultHandler, 
    onMinutesChange = defaultHandler, 
    onSecondsChange = defaultHandler,
    hoursOwed = 0,
    minutesOwed = 0,
    secondsOwed = 0
} : TimeOwedInputProps) => {
    return (
        <Stack direction='row'>
            <TimeInput label='Hours' onTimeChange={onHoursChange} value={hoursOwed} />
            <TimeInput label='Minutes' onTimeChange={onMinutesChange} value={minutesOwed} />
            <TimeInput label='Seconds' onTimeChange={onSecondsChange} value={secondsOwed} />
        </Stack>
    )
}