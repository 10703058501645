import { RootState } from "app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BehaviorCodeTimer } from "dtos/timers";

interface BehaviorCodeTimerState {
    timers: {}
}

const initialState: BehaviorCodeTimerState = {
    timers: {}
}

export const timersSlice = createSlice({
    name: 'timers',
    initialState,
    reducers: {
        putTimer: (state, action: PayloadAction<BehaviorCodeTimer>) => {
            // @ts-ignore
            state.timers[action.payload.behavior_code_id] = action.payload
        },
        deleteTimer: (state, action: PayloadAction<BehaviorCodeTimer>) => {
            // @ts-ignore
            delete state.timers[action.payload.behavior_code_id]
        }
    }
})

export const { putTimer, deleteTimer } = timersSlice.actions
export const selectTimers = (state: RootState) => state.timers.timers
export default timersSlice.reducer