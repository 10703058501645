import React, {useEffect, useState} from 'react';
import { useAppDispatch } from '../../../app/hooks';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DialogTitle from '@mui/material/DialogTitle';
import { Student, Class, Teacher, ReferralCode } from '../../../types/dtos';
import { addToPending } from '../referralsSlice';
import { Container, FormControl, FormControlLabel, InputLabel } from '@mui/material';
import {CancelButton} from '../../../components/CancelButton'
import {SubmitButton} from '../../../components/SubmitButton'

export interface CreateReferralProps {
    teacher: Teacher
    referrer: Teacher
    student: Student
    clazz: Class
    setOpen: Function
}

export const CreateReferral = (props : CreateReferralProps) => {
    const [teacher, setTeacher] = useState(props.teacher)
    const [student, setStudent] = useState(props.student)
    const [referrer, setReferrer] = useState(props.referrer)
    const [code, setCode] = useState("AGGR" as ReferralCode)
    const [time_owed, setTimeOwed] = useState(10)
    const [escort_required, setEscortRequired] = useState(false)
    const [location, setLocation] = useState(props.clazz.location || "")
    const [readyToClose, setReadyToClose] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (readyToClose) {
            let referral_date = new Date()
            dispatch(
                addToPending({
                    id: (Math.random() + 1).toString(36).substring(7),
                    teacher,
                    referrer,
                    student,
                    code,
                    class: {
                        id: props.clazz.id,
                        school_id: props.clazz.school_id,
                        district_id: props.clazz.district_id,
                        name: props.clazz.name,
                        period: props.clazz.period,
                        location: props.clazz.location
                    },
                    time_owed,
                    escort_required,
                    location,
                    discharged: false,
                    work_completed: false,
                    referral_time: Math.floor(referral_date.getTime() / 1000),
                    referral_date_string: referral_date.toLocaleDateString(),
                    referral_time_string: referral_date.toLocaleTimeString()
                })
            )

            setReadyToClose(false)
            props.setOpen(false)
        }
    }, [readyToClose])

    return (
        <Container>
            <DialogTitle sx={{ margin: "auto", textAlign: 'center' }}>Call Code</DialogTitle>

            <DialogContent sx={{ minWidth: '15rem'}}>
                <Stack 
                    direction="column"
                    spacing={5}
                >
                    <TextField 
                        id="student"
                        label="Student"
                        defaultValue={student.full_name}
                        variant="standard"
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                            readOnly: true
                        }}
                    />

                    <FormControl>
                        <InputLabel id="code-type-label">Code Type</InputLabel>
                            <Select
                                labelId="code-type-label"
                                label="Code Type"
                                id="code-type-form"
                                value={code}
                                onChange={(e) => { setCode(e.target.value as unknown as ReferralCode) }}
                            >   
                                
                                <MenuItem value={code}>Aggression</MenuItem>
                                <MenuItem value={ReferralCode.DisruptiveBehavior}>Disruptive Behavior</MenuItem>
                                <MenuItem value={ReferralCode.Elopement}>Elopement</MenuItem>
                                <MenuItem value={ReferralCode.Language}>Language</MenuItem>
                                <MenuItem value={ReferralCode.NonCompliance}>Non Compliance</MenuItem>
                                <MenuItem value={ReferralCode.PropertyDestruction}>Property Destruction</MenuItem>
                            </Select>
                    </FormControl>

                    <TextField 
                        id="timeOwed"
                        label="Time Owed"
                        defaultValue={time_owed}
                        value={time_owed}
                        variant='standard'
                        type="number"
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]{1,2}',
                            min: 0,
                            style: { textAlign: 'center' }
                        }}
                        onChange={(e) => { setTimeOwed(parseInt(e.target.value)) }}
                    />

                    <FormControlLabel 
                        value="bottom"
                        label="Escort Required"
                        labelPlacement='top'
                        control={
                            <Switch 
                                color='secondary'
                                checked={escort_required}
                                onChange={(e) => { setEscortRequired(e.target.checked)}}
                            />
                        }
                    />

                    
                    <TextField 
                        id="location"
                        label="Location"
                        defaultValue={props.clazz.location}
                        variant="standard"
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                            readOnly: true
                        }}
                    />                    

                    <TextField 
                        id="teacher"
                        label="Teacher"
                        defaultValue={teacher.full_name}
                        variant="standard"
                        InputProps={{
                            inputProps: { style: { textAlign: 'center' } },
                            readOnly: true
                        }}
                    />
                </Stack>
            </DialogContent>

            <DialogActions >
                <Stack 
                    direction="row"
                    sx={{ justifyContent: 'space-between', width: '100%' }}
                >
                    <CancelButton onClick={() => { props.setOpen(false) }}>
                        Cancel
                    </CancelButton>

                    <SubmitButton onClick={() => { setReadyToClose(true) } }>
                        Submit
                    </SubmitButton>
                </Stack>
            </DialogActions>
        </Container>
    )
}