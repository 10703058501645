import React, { useState } from 'react'
import { Grid } from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { selectActiveReferrals, ActiveReferral } from '../referralsSlice';
import { ActiveReferralGridItem } from './ActiveReferralGridItem';

export const ActiveReferralGrid = () => {
    const activeReferrals = useAppSelector(selectActiveReferrals)

    return (
        <Grid 
            container
            columns={5} 
            spacing={2}
            wrap="wrap"
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            paddingTop='2rem'
        >
            { 
                Object.entries(activeReferrals).map(
                    (value: [string, ActiveReferral], index: number) => (
                        <ActiveReferralGridItem referral={value[1]} />
                    )
                ) 
            }   
        </Grid>
    )
}