import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Container, Stack, Divider, Box, IconButton } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { useAppSelector } from '../../../app/hooks';
import { selectDischargedReferrals } from '../referralsSlice';
import { DischargedReferralGridItem } from './DischargedReferralGridItem';
import { asArray } from '../../filters/utils';

export const DischargedReferralGrid = () => {
    const [ searchParams ] = useSearchParams()
    const dischargedReferrals = useAppSelector(selectDischargedReferrals)
    const [show, setShow] = useState<number>(10)
    // const [before, setBefore] = useState<Date>(getEndOfToday())
    // const [after, setAfter] = useState<Date>(getStartOfDaysPrior(30))

    return (
        <Container sx={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
            <Stack  
                spacing={2}
                alignItems='center'
                divider={<Divider orientation='horizontal' />}
            >
                { 
                    asArray(dischargedReferrals)
                    .slice() // reverse is destructive, create a copy first
                    .reverse()
                    .slice(0, show)
                    .map(
                        (r) => (
                            <DischargedReferralGridItem key={r.id} referral={r} />
                        )
                    ) 
                }   

                <Box>
                    <IconButton 
                        size='large'
                        color="secondary" 
                        onClick={() => setShow(show + 10)}
                    >
                        <AddCircle />
                    </IconButton>
                </Box>
            </Stack>
        </Container>
    )
}