import React, { useState } from 'react'
import { SelectableTimer } from 'components/timers/SelectableTimer'
import { Grid, Box, Dialog } from '@mui/material';
import { RemoveBasicBehaviorCodeDialog } from './RemoveBasicBehaviorCodeDialog';
import { BasicBehaviorCodeTimerSelector } from './BasicBehaviorCodeTimerSelector';
import { BehaviorCodeTimer } from 'dtos/timers';
import { BasicBehaviorCodeTimerControl } from './BasicBehaviorCodeTimerControl';
import { BasicBehaviorCodeTimerColorPicker } from './BasicBehaviorCodeTimerColorPicker';
import { EditBasicBehaviorCodeButton } from './EditBasicBehaviorCodeButton';


interface BasicBehaviorCodeGridItemProps {
    behaviorCodeTimer: BehaviorCodeTimer
}

export const BasicBehaviorCodeGridItem = ({ behaviorCodeTimer } : BasicBehaviorCodeGridItemProps) => {
    const [ open, setOpen ] = useState<boolean>(false);

    return (
        <Grid item sm={1} key={behaviorCodeTimer.behavior_code_id}>
            <Box borderRadius={2} boxShadow={2} sx={{ position: 'relative', cursor: 'pointer' }} onClick={(e) => { setOpen(true) }}>
                
                <BasicBehaviorCodeTimerColorPicker behaviorCodeTimer={behaviorCodeTimer} />
                <EditBasicBehaviorCodeButton behaviorCodeTimer={behaviorCodeTimer} />
                <SelectableTimer behaviorCodeTimer={behaviorCodeTimer} />
                <BasicBehaviorCodeTimerSelector behaviorCodeTimer={behaviorCodeTimer} />
                <BasicBehaviorCodeTimerControl behaviorCodeTimer={behaviorCodeTimer} />
            </Box>
            
            <Dialog open={open} onClose={(e) => { setOpen(false) }}>
                <RemoveBasicBehaviorCodeDialog behaviorCodeTimer={behaviorCodeTimer} setOpen={setOpen}/>
            </Dialog>
        </Grid>
        
    )
}