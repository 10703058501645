import { Teacher } from "../types/dtos"

export const teachers = [
    {
        id: "10",
        full_name: "David Tennant",
        first_name: "David",
        last_name: "Tennant",
        image: "https://upload.wikimedia.org/wikipedia/en/2/21/Tenth_Doctor_%28Doctor_Who%29.jpg"
    },
    {
        id: "11",
        full_name: "Matt Smith",
        first_name: "Matt",
        last_name: "Smith",
        image: "https://www.biography.com/.image/t_share/MTIwNjA4NjM0MTk5NjM5NTY0/on-location-for-doctor-who.jpg"
    },
    {
        id: "12",
        full_name: "Peter Capaldi",
        first_name: "Peter",
        last_name: "Capaldi",
        image: "https://www.doctorwhotv.co.uk/wp-content/uploads/capaldi-sfx-251.jpg"
    },
    {
        id: "13",
        full_name: "Jodie Whittaker",
        first_name: "Jodie",
        last_name: "Whittaker",
        image: "https://ichef.bbci.co.uk/images/ic/1200x675/p06n8sqv.jpg"
    }
] as Teacher[]