import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Countdown } from './Countdown';
import { DonutFill } from './DonutFill';
import { LinearFill } from './LinearFill';
import { DonutDrain } from './DonutDrain';
import { LinearDrain } from './LinearDrain'
import styles from './SelectableTimer.module.css'
import { BehaviorTimerProvider } from 'contexts/BehaviorTimerContext';
import { TimerType } from './types';
import { BehaviorCodeTimer } from 'dtos/timers';


export interface SelectableTimerProps {
    behaviorCodeTimer: BehaviorCodeTimer
}

/**
 * @param props Partial of SelectableTimerProps - timer_type, time_owed, end_epoch_seconds required
 * @param props.timer_type Enum of timer type
 * @param props.time_owed Minutes owed
 * @param props.end_epoch_seconds End epoch s
 * @returns 
 */
const determineTimerComponent = function(behaviorCodeTimer : BehaviorCodeTimer ) : JSX.Element {
    switch(behaviorCodeTimer.timer_type) {
        case TimerType.DonutFill:
            return (<DonutFill behaviorCodeTimer={behaviorCodeTimer} />)
        case TimerType.LinearFill:
            return (<LinearFill behaviorCodeTimer={behaviorCodeTimer} />)
        case TimerType.DonutDrain:
            return (<DonutDrain behaviorCodeTimer={behaviorCodeTimer} />)
        case TimerType.LinearDrain:
            return (<LinearDrain behaviorCodeTimer={behaviorCodeTimer} />)
        default:
            return (<Countdown behaviorCodeTimer={behaviorCodeTimer} />)
    }
}

export const SelectableTimer = ({ behaviorCodeTimer } : SelectableTimerProps) => {
    const [active, setActive] = useState<boolean>(true)
    
    return (
        <Box 
            className={!active ? styles['pulse-green'] : ''}
            borderRadius={2}
        >        
            <Stack 
                spacing={2}
                direction='column'
                alignItems='center'
            >   
                <Box
                    display='flex'
                    height='5rem'
                    width='100%'
                    justifyContent='center'
                    alignItems='center'
                >
                    <BehaviorTimerProvider value={{ active, setActive }}>
                        { determineTimerComponent(behaviorCodeTimer) }
                    </BehaviorTimerProvider>
                </Box>

                <Typography flexGrow={1} variant='h5'>{behaviorCodeTimer.timer_label}</Typography>
            </Stack>
        </Box>
    )
}