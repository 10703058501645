import React from 'react';
import { Link } from 'react-router-dom'
import { Tab, Tabs } from "@mui/material";
import useRouteMatch from "../../../hooks/useRouteMatch";

export const EnterpriseRouteTabs = () => {
    // const routeMatch = useRouteMatch(['/classes/:classId', '/classes', '/quick-room']);
    // const currentTab = routeMatch?.pattern?.path;
    const currentTab = useRouteMatch([
        "/",
        '/classes/:classId', 
        '/classes', 
        '/quick-room'
    ]);

    return (
        <Tabs 
            value={currentTab}
            variant="fullWidth"
            textColor="inherit"
            indicatorColor="secondary"
            sx={{ textColor: '#FFFFFF' }}
        >
            <Tab label="Dashboard" value="/" to="/" component={Link} />
            <Tab label="Classes" value="/classes" to="/classes" component={Link}  />
            <Tab label="Quick Room" value="/quick-room" to="/quick-room" component={Link} />
            <Tab label="Behavior Codes" value="/codes" to="/codes" component={Link} />
        </Tabs>
    )
}