import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Profile} from "./profile/Profile"
import { PlanDependent } from 'components/logical/PlanDependent';
import { BasicRouteTabs } from './tabs/BasicRouteTabs';
import { EnterpriseRouteTabs } from './tabs/EnterpriseRouteTabs';

export function NavigationBar() {
  return (
    <>
      <AppBar position='fixed'>
          <Toolbar >
              <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                  >
                  QI
              </Typography>
              
              <Box sx={{ flexGrow: 1 }}>
                  <PlanDependent
                    basic={<BasicRouteTabs />}
                    enterprise={<EnterpriseRouteTabs />}
                  />
              </Box>
              
              <Profile />
          </Toolbar>
      </AppBar>
      <Toolbar/>
    </>
  );
};