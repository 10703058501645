import { Referral } from '../../types/dtos';
import { Filter, FilterOperator, ReferralGroup } from './types'
import moment from 'moment'

export const getAverageTimeOwed = (referrals : Referral[]) => {
    let times = referrals.map((r) => r.time_owed)

    let total = times.reduce(
        // @ts-ignore
        (previousValue, nextValue) => previousValue + nextValue, 
        0
    )
    
    // @ts-ignore
    let avg = Math.round( total / times.length)
    
    return isNaN(avg) ? 0 : avg
}

export const getStartOfToday = () : Date => {
    return moment().startOf('day').toDate()
}

export const getEndOfToday = () : Date => {
    return moment().endOf('day').toDate()
}   

export const getStartOfDaysPrior = (daysPrior : number) : Date => {
    return moment().subtract(daysPrior, 'days').startOf('day').toDate()
}

export const isSameDay = (before : Date, after : Date) : boolean => {
    return before.toLocaleDateString() === after.toLocaleDateString()
}

/**
 * 
 * @param {Referall} referrals 
 * @returns Array of referrals sorted with most recent referrals first
 */
export const asArray = (referrals : Record<string, Referral>) => {
    return Object.values(referrals).sort(
        // @ts-ignore
        (a,b) =>  (a.referral_time > b.referral_time) ? 1 : -1
    )
}

export const filterByDate = (referrals : Record<string, Referral>, after : Date, before : Date = new Date()) : Record<string, Referral> => {
    let beforeEpochSeconds = Math.floor(before.getTime() / 1000)
    let afterEpochSeconds = Math.floor(after.getTime() / 1000)
    let filteredReferrals : Record<string, Referral> = {}
    let refs = asArray(referrals)

    for (let i = 0; i < refs.length; i++) {
        let ref = refs[i];

        // @ts-ignore
        if (ref?.referral_time < beforeEpochSeconds && ref?.referral_time > afterEpochSeconds ) {
            // @ts-ignore
            filteredReferrals[ref?.id] = ref
        }
    }

    return filteredReferrals
}

export const stringEnumHas = (e : any, value : string) : Boolean => {
    let enumValues = Object.values(e) as string[]
    return enumValues.includes(value)
}

export const toEpochSeconds = (date : Date) : number => {
    return Math.floor(date.getTime() / 1000)
}

export const passesFilter = (filter : Filter, referral: Referral) : Boolean => {
    let referralPropertyValue = getPropertyValue(filter.property, referral)

    switch(filter.operator) {
        case FilterOperator.EQ:
            return filter.value === referralPropertyValue
        case FilterOperator.GT:
            return filter.value > referralPropertyValue
        case FilterOperator.LT:
            return filter.value < referralPropertyValue
        default:
            return false
    }
}

export const passesAllFilters = (filters : Filter[], referral : Referral) : Boolean => {
    for (let i = 0; i < filters.length; i++) {
        let filter = filters[i];

        if (!passesFilter(filter, referral)) {
            return false
        }
    }
    return true
}

export const filterReferrals = (filters : Filter[], referrals: Record<string, Referral>) : Record<string,Referral> => {
    let filteredReferrals : Record<string,Referral> = {}
    let refs = asArray(referrals)

    for (let i = 0; i < refs.length; i++) {
        let ref = refs[i];

        if(passesAllFilters(filters, ref)) {
            // @ts-ignore
            filteredReferrals[ref.id] = ref
        }
    }

    return filteredReferrals
}

export const removeFilter = (filters: Filter[], filter: Filter) : Filter[] => {
    let newFilters : Filter[] = []

    for (let i = 0; i < filters.length; i++) {
        let f = filters[i]
        // only keep if at least one value is different
        // if all values are the same, it is to be removed
        if (
            f.property !== filter.property && 
            f.operator !== filter.property && 
            f.value !== filter.value
        ) {
            newFilters.push(filter)
        }
    }

    return newFilters
}

export const addFilter = (filters: Filter[], filter: Filter) : Filter[] => {
    return filters.slice().concat([filter])
}

export const putFilter = (filters : Filter[], property: string, newFilter : Filter) : Filter[] => {
    let updated = false
    let newFilters = filters.slice()
    
    for (let i = 0; i < newFilters.length; i++) {
        let filter = newFilters[i]

        if (filter.property === property) {
            newFilters[i] = newFilter
            updated = true
        }
    } 
    
    if (!updated) {
        newFilters.push(newFilter)
    }

    return newFilters
}

export const groupByProperty = (property : string, referrals : Record<string, Referral>) : ReferralGroup[] => {
    let referralsByProperty = {} as Record<any, Referral[]>
    let refs = asArray(referrals)

    for (let i = 0; i < refs.length; i++) {
        let ref = refs[i];
        let propertyValue = getPropertyValue(property, ref) 
        
        if (referralsByProperty.hasOwnProperty(propertyValue)) {
            referralsByProperty[propertyValue] = referralsByProperty[propertyValue].concat([ref])
        } else {
            referralsByProperty[propertyValue] = [ref]
        }
    }
    
    let asObjects = Object.entries(referralsByProperty).map(
        // key is the property by which referrals are grouped
        // value is the array of values
        ([key, value]) => {
            return {
                property: key,
                count: value.length,
                referrals: value
            }
        }
    )

    return asObjects
}

export const getPropertyValue = (property : string, obj : Record<string, any>) : any => {
    let propChain = property.split(".")

    if (propChain.length === 1) {
        return obj[property]
    } 

    return getPropertyValue(
        propChain.slice(1).join("."), // recreate prop chain minus first element
        obj[propChain[0]]
    )    
}

export const maxByProperty = (property : string, referrals : Record<string, Referral>) => {
    let refs = asArray(referrals)

    let byProperty = {}

    for (let i = 0; i < refs.length; i++) {
        let referral = refs[i]
        let propertyValue = getPropertyValue(property, referral)
        
        //@ts-ignore
        if (byProperty.hasOwnProperty(propertyValue)) {
            //@ts-ignore
            byProperty[propertyValue] += 1
        } else {
            //@ts-ignore
            byProperty[propertyValue] = 1
        }        
    }

    let max = {
        value: 'N/A',
        count: 0
    }

    Object.entries(byProperty).map((value) => {
        //@ts-ignore
        if (value[1] > max.count) {
            max.value = value[0]
            //@ts-ignore
            max.count = value[1]
        }
    })

    if (max.count === 1 && Object.keys(byProperty).length > 1) {
        return {
            value: 'N/A',
            count: 1
        }
    }

    return max
}

export const getMostReferredBehavior = (referrals : Record<string, Referral>) => {
    let refs = asArray(referrals)

    let byType = {}

    for (let i = 0; i < refs.length; i++) {
        let referral = refs[i]

        //@ts-ignore
        if (byType.hasOwnProperty(referral.code)) {
            //@ts-ignore
            byType[referral.code] += 1
        } else {
            //@ts-ignore
            byType[referral.code] = 1
        }        
    }

    let mostReferred = {
        type: 'N/A',
        count: 0
    }

    Object.entries(byType).map((value) => {
        //@ts-ignore
        if (value[1] > mostReferred.count) {
            mostReferred.type = value[0]
            //@ts-ignore
            mostReferred.count = value[1]
        }
    })

    if (mostReferred.count === 1 && Object.keys(byType).length > 1) {
        return {
            type: 'N/A',
            count: 1
        }
    }

    return mostReferred
}