import React, { useContext, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { useCountdown } from 'react-time-sync';
import { TimerProps } from './types';
import { epochSecondsToEpoch } from 'functions/time';
import TimerContext from 'contexts/BehaviorTimerContext';


export const LinearDrain = ({ behaviorCodeTimer }: TimerProps) => {
    const { active, setActive} = useContext(TimerContext)
    const [ progress, setProgress ] = useState<number>(0)
    const secondsLeft = useCountdown({ until: epochSecondsToEpoch(behaviorCodeTimer.timer_end_epoch_seconds) })

    useEffect(() => {
        if (!behaviorCodeTimer.paused) {
            if (secondsLeft === 0) {
                setActive(false)
            }
    
            let totalSecondsCompleted = behaviorCodeTimer.seconds_owed - secondsLeft
    
            setProgress(
                Math.round(100 - (totalSecondsCompleted / behaviorCodeTimer.seconds_owed) * 100)
            )
        }
    }, [secondsLeft, setActive, behaviorCodeTimer.paused, behaviorCodeTimer.seconds_owed])

    // implementation stolen from 
    // https://smartdevpreneur.com/the-essential-guide-to-the-material-ui-progress-bar-color-percent-and-more/
    // '80' follows background color to alter opacity. if picker is modified in
    // ColorPicker element, this will need to update
    return (
        <Container>
            <LinearProgress
                variant='determinate'
                value={progress} 
                sx={{ 
                    height: '1rem', 
                    borderRadius: 20, 
                    backgroundColor: active ? `${behaviorCodeTimer.color}80` : '#4CAF50', 
                    "& .MuiLinearProgress-bar": {
                        backgroundColor: active ? behaviorCodeTimer.color : '#4CAF50'
                    }
                }}
            />
        </Container>        
    )
}