import { Typography, Stack, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DashboardDataBox } from '../../../components/DashboardDataBox';
import { useNavigate } from 'react-router-dom';
import { selectActiveReferrals, selectDischargedReferrals, selectPendingReferrals } from '../../referral/referralsSlice';
import { asArray, filterByDate, getEndOfToday, getStartOfToday } from '../../filters/utils';

const oneDayInSeconds = 86400

export const DailyStats = () => {
    const navigate = useNavigate()
    const [ inTransit, setInTransit ] = useState(0)
    const [ inProgress, setInProgress ] = useState(0)
    const [ done, setDone] = useState(0)
    const pending = useSelector(selectPendingReferrals)
    const active = useSelector(selectActiveReferrals)
    const discharged = useSelector(selectDischargedReferrals)
    
    useEffect(() => {
        setInTransit(
            Object.entries(pending).length
        )
    }, [])

    useEffect(() => {
        setInProgress(
            Object.entries(active).length
        )
    }, [])

    useEffect(() => {
        let dailyDischarged = asArray(
            filterByDate(discharged, getStartOfToday(), getEndOfToday())
        )

        // let now = Math.floor(Date.now() / 1000)

        // let dailyDischarged = Object.entries(discharged)
        // .map((value) => value[1])
        // .filter(
        //     (r) => { 
        //         if (r.referral_time > now - oneDayInSeconds) {
        //             return r
        //         }
        //     }
        // )

        setDone(dailyDischarged.length)
    }, [discharged])

    return (
        <Stack
            paddingTop={2}
            direction='row'
            justifyContent='space-around'
            flexBasis={100}
            width='100%'
            spacing={5}
        >   
            <Box onClick={(e) => { navigate("/quick-room") }}>
                <DashboardDataBox 
                    data={inTransit}
                    description={(inTransit === 1 ? 'student':'students') + ' in transit'}
                    
                    sx={{ cursor: 'pointer' }}
                />
            </Box>

            <Box onClick={(e) => { navigate("/quick-room") }}>
                <DashboardDataBox 
                    data={inProgress}
                    description={(inProgress === 1 ? 'student':'students') +  ' present'}
                    
                    sx={{ cursor: 'pointer' }}
                />
            </Box>
            
            <Box onClick={(e) => { navigate("/codes") }}>
                <DashboardDataBox 
                    data={done}
                    description={(done === 1 ? 'student':'students') + ' discharged'}
                    
                    sx={{ cursor: 'pointer' }}
                />
            </Box>
        </Stack>
    )
}