import { Button, styled } from "@mui/material";

export const CancelButton = styled(Button)(
    ({ theme }) => ({
        color: theme.palette.error.main,
        backgroundColor: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.error.main,
        }
    })
);