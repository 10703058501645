import React from 'react'
import { Container } from '@mui/material';
import { ClassesList } from '../features/class/list/ClassesList'

export const Classes = () => {
    return (
        <Container
            sx={{ 
                minHeight: '100vh',
                bgcolor: '#ffffff',
                boxShadow: 5,
            }}
        >
            <ClassesList />
        </Container>
    )
}