import React from 'react'
import { Label } from 'recharts';
import { LineChart, LineChartProps } from './LineChart'


const getYDomainMax = (dataMax : number) : number => {
    return dataMax + Math.floor(0.1*dataMax) + 1
}

export const MultiDayLineChart = ({data, lines} : LineChartProps) => {
    return (
        <LineChart 
            title="TODO"
            data={data}
            lines={lines}
            xAxisProps={{
                // property key points to referral_date_string for mutli day
                // grouped referrals
                dataKey: 'property', 
                type: 'category',
                domain: ['auto', 'auto'],
                name: 'Time',
                dy: 20,
                height: 100,
                angle: 45,
            }}
            yAxisProps={{
                type: "number",
                domain: [0, getYDomainMax],
                allowDecimals: false,
                // @ts-ignore
                label: (
                    <Label angle={-90} value='Number of Codes' position='insideLeft' style={{textAnchor: 'middle'}}  />
                )
            }}
        />
    )
}