import React from 'react'
import { LineChart as ReLineChart, Line as ReLine, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ChartProps } from './types';

export interface Line {
    dataKey: string
    color?: string
    type?: string
}

export interface LineChartProps extends ChartProps {
    lines: Line[]
}

export const LineChart = ({data, lines, xAxisProps = {}, yAxisProps = {}, tooltipContent} : LineChartProps) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <ReLineChart width={400} height={400} data={data}>   
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Tooltip content={ tooltipContent ? tooltipContent : undefined}/>
                <XAxis  {...xAxisProps} />
                <YAxis {...yAxisProps} />
                {
                    lines.map((l : Line) => (
                        <ReLine 
                            key={Math.random().toString().slice(2, 10)}
                            dataKey={l.dataKey}
                            stroke={l.color || "#2492D9"} 
                            activeDot={{ r: 6, stroke: '#eb942d' }}
                        />
                    ))
                }
            </ReLineChart>
      </ResponsiveContainer>
    )
}